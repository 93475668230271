<template>
  <div class="bg-white p-4 rounded-md w-full">
    <h2 class="text-xl font-semibold text-gray-800 mb-4">
      {{ t('settings.components.sources.websiteConfiguration') }} </h2>
    <div class="space-y-4">
      <custom-table :data="internalSourcesWithIds" :columns="tableColumns" :is-loading="false" :show-search="false" :show-sort="false" :show-filter="false">
        <template #table-actions>
          <span class="flex items-center space-x-1">
            <custom-button icon="add" iconPosition="right" @click="addSource" :button-text="t('settings.components.sources.components.sourceTable.addSourceButton')" size="sm"/>
            <VTooltip :title="t('common.tooltipInformations.websiteConfiguration')" information>
              <template #content>
                <icon-component icon="info" iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"/>
              </template>
            </VTooltip>
          </span>
        </template>

        <template #category="{ item }">
          <CustomDropdown :modelValue="item.sourceCategoryId" @update:modelValue="(value) => handleInputChange(item.id, 'sourceCategoryId', value)" :options="localizedCategoryOptions" :loading="false" size="sm" :show-search="true" :show-error="false"/>
        </template>

        <template #getUrl="{ item }" v-if="Number(assetServiceTypeProp) !== 2">
          <custom-input icon="link" :show-icon="true" :modelValue="item.getUrl" @update:modelValue="(value) => handleInputChange(item.id, 'getUrl', value)" size="sm" :show-error="false"/>
        </template>

        <template #addUrl="{ item }">
          <custom-input icon="link" :show-icon="true" :modelValue="item.addUrl" @update:modelValue="(value) => handleInputChange(item.id, 'addUrl', value)" size="sm" :show-error="false"/>
        </template>

        <template #editUrl="{ item }" v-if="Number(assetServiceTypeProp) !== 2">
          <custom-input icon="link" :show-icon="true" :modelValue="item.editUrl" @update:modelValue="(value) => handleInputChange(item.id, 'editUrl', value)" size="sm" :show-error="false"/>
        </template>

        <template #actions="{ item }">
          <div class="flex items-center justify-center">
            <button v-if="item.id !== 1" @click.stop="removeSource(item.id)" class="text-lobster-500 hover:text-lobster-600">
              <span class="material-symbols-outlined text-sm">delete</span>
            </button>
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import CustomDropdown from '@/components/base/inputs/VDropdown.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomTable from '@/components/table/VTable.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import VTooltip from '@/components/common/VTooltip.vue'
import IconComponent from '@/components/common/VIcon.vue'

const categories = [
  {
    id: 1,
    translations: {
      tr: 'Hizmetler',
      en: 'Services',
    },
  },
  {
    id: 2,
    translations: {
      tr: 'Blog',
      en: 'Blog',
    },
  },
  {
    id: 3,
    translations: {
      tr: 'Hakkımızda',
      en: 'About Us',
    },
  },
  {
    id: 4,
    translations: {
      tr: 'Sosyal Medya',
      en: 'Social Media',
    },
  },
  {
    id: 5,
    translations: {
      tr: 'Kategoriler',
      en: 'Categories',
    },
  },
  {
    id: 7,
    translations: {
      tr: 'İletişim',
      en: 'Contact',
    },
  },
  {
    id: 8,
    translations: {
      tr: 'Lokalizasyon',
      en: 'Localization',
    },
  },
  {
    id: 9,
    translations: {
      tr: 'Görsel',
      en: 'Image',
    },
  },
  {
    id: 10,
    translations: {
      tr: 'Tek İçerik',
      en: 'Single Content',
    },
  },
]

const props = defineProps({
  useStore: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  assetServiceTypeProp: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['update:modelValue'])

const assetStore = useAssetStore()
const { t, locale } = useI18n()

const tempIdCounter = ref(1)
const internalValue = ref([])

const internalSourcesWithIds = computed(() => {
  return internalValue.value.map((item) => {
    if (item.id && !String(item.id).startsWith('temp_')) {
      return { ...item }
    } else {
      return {
        ...item,
        id: item.id || `temp_${tempIdCounter.value++}`,
      }
    }
  })
})

const localizedCategoryOptions = computed(() => {
  return categories.map((category) => ({
    value: category.id,
    label: category.translations[locale.value] || category.translations.en,
  }))
})

const assetServiceType = computed(() => {
  if (props.useStore) {
    return assetStore.assetServiceType
  }
  return props.assetServiceTypeProp
})

const tableColumns = computed(() => [
  {
    key: 'category',
    label: t('settings.components.sources.components.sourceTable.categoryHeader'),
  },
  ...(Number(assetServiceType.value) !== 2
      ? [
        {
          key: 'getUrl',
          label: t('settings.components.sources.components.sourceTable.getUrlHeader'),
        },
      ]
      : []),
  {
    key: 'addUrl',
    label: t('settings.components.sources.components.sourceTable.addUrlHeader'),
  },
  ...(Number(assetServiceType.value) !== 2
      ? [
        {
          key: 'editUrl',
          label: t('settings.components.sources.components.sourceTable.editUrlHeader'),
        },
      ]
      : []),
  {
    key: 'actions',
    label: '#',
    width: 'w-1/12',
    align: 'center',
  },
])

const handleInputChange = (itemId, field, value) => {
  const updatedSources = internalSourcesWithIds.value.map((item) => {
    if (item.id === itemId) {
      return {
        ...item,
        [field]: value,
      }
    }
    return item
  })
  internalValue.value = updatedSources
  emit('update:modelValue', updatedSources)
}

const addSource = () => {
  const newSource = {
    sourceCategoryId: '',
    getUrl: '',
    addUrl: '',
    editUrl: '',
  }
  internalValue.value = [...internalValue.value, newSource]
  emit('update:modelValue', internalValue.value)
}

const removeSource = (id) => {
  internalValue.value = internalValue.value.filter((item) => item.id !== id)
  emit('update:modelValue', internalValue.value)
}

// Initialize internal state when props change
watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    internalValue.value = JSON.parse(JSON.stringify(newValue))
  }
}, { immediate: true, deep: true })

// Watch store changes if useStore is true
watch(
    () => assetStore.assetData.sources,
    (newValue) => {
      if (props.useStore && newValue) {
        internalValue.value = JSON.parse(JSON.stringify(newValue))
        emit('update:modelValue', internalValue.value)
      }
    },
    { deep: true },
)
</script>