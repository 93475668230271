<template>
  <Alert v-if="showWarning" class="mb-4" layout="px-6" status="warning" :message="t('dashboard.components.keywordAnalysis.warnings.lowVolumeWarning', {
    count: deletedKeywords.length,
  })
    " :hasAction="true" @action="showDeletedKeywordsModal = true" :actionText="t('dashboard.components.keywordAnalysis.warnings.viewDetails')" :onDismiss="dismissWarning"/>
  <div class="bg-white p-4 rounded-md shadow-md relative">
    <CustomTable :data="keywords" :columns="dynamicColumns" v-bind="tableProps" @page-change="handlePageChange" @search="handleSearch" min-width="900px">
      <template #table-actions>
        <div class="flex items-center justify-between w-full mr-2">
          <div class="flex items-center space-x-2">
            <h1 class="text-base font-semibold">
              {{ t('dashboard.components.keywordAnalysis.title') }} </h1>

            <Tooltip :title="t('dashboard.components.keywordAnalysis.tooltips.exportTooltip')
              " v-if="keywords.length > 0">
              <template #content>
                <div class="flex items-center">
                  <span @click="exportToExcel" class="material-symbols-outlined text-secondary cursor-pointer !text-[22px] hover:text-primary" :class="excelExporting ? 'animate-spin' : ''">
                    {{ excelExporting ? 'progress_activity' : 'export_notes' }}
                  </span>
                </div>
              </template>
            </Tooltip>
            <Tooltip :title="t('common.tooltipInformations.keywordAnalysis')" information>
              <template #content>
                <icon-component icon="info" iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"/>
              </template>
            </Tooltip>
          </div>
        </div>
      </template>

      <!--      <template #extra-actions>-->
      <!--        <div class="flex gap-3 ml-auto mr-3">-->
      <!--          <div>-->
      <!--            <DateRangePicker v-model="datePickerModel" :currentYearOnly="true" class="w-full"/>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <VSelectMenu v-model="selectedItems" :items="items" class="w-full"/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->

      <!-- Base Slots -->
      <template #number="{ item, index }">
        {{ calculateRank(index) }}
      </template>

      <template #yandex="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 1).formattedDate" :displayCondition="true" :maxWidth="300">
            <template #content>
              <span :class="[getMaxPositionData(item, 1).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 1).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #google="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 2).formattedDate" :displayCondition="true" :maxWidth="300">
            <template #content>
              <span :class="[getMaxPositionData(item, 2).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 2).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #bing="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 3).formattedDate" :displayCondition="true" :maxWidth="300">
            <template #content>
              <span :class="[getMaxPositionData(item, 3).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 3).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #volume="{ item }">
        <div class="flex items-center justify-center w-full">
          <span :class="[
            getVolumeDisplay(item).class,
            'whitespace-nowrap overflow-hidden text-ellipsis',
          ]" :title="getVolumeDisplay(item).fullText">
            {{ getVolumeDisplay(item).displayText }}
          </span>
        </div>
      </template>

      <template #inContent="{ item }">
        <div class="flex items-center justify-center">
          <span>{{ item.entityCount }}</span>
        </div>
      </template>

      <!-- Type 1 Specific Slots -->
      <!-- <template #competitorCount="{ item }" v-if="type === '1'">
        <div class="flex items-center justify-center">
          <span>{{ item.competitorCount }}</span>
        </div>
      </template>

      <template #source="{ item }">
        <div class="flex items-center justify-center">
          <span
            :class="{
              'cursor-pointer text-blue-600': item.type === 3 && type === '1',
              'cursor-default': item.type !== 1,
            }"
          >
            {{ getSourceTypeLabel(item.type) }}
          </span>
        </div>
      </template> -->

      <template #reporting="{ item }" v-if="type === '1'">
        <div class="flex items-center justify-center space-x-4">
          <Tooltip :title="t(
            'assets.dashboard.keywordAnalysis.modals.categoryTable.title'
          )
            " :display-condition="true">
            <template #content>
              <span class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer" @click="categoryKeywordModal(item)">
                account_tree
              </span>
            </template>
          </Tooltip>
          <!-- <Tooltip
            :title="
              t(
                'dashboard.components.keywordAnalysis.modals.positionGraph.title'
              )
            "
            :display-condition="true"
          >
            <template #content>
              <span
                class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer"
                @click="toggleMonthlyPositionModal(item)"
              >
                notes
              </span>
            </template>
          </Tooltip>
          <Tooltip
            :title="
              t('dashboard.components.keywordAnalysis.modals.volumeGraph.title')
            "
            :display-condition="true"
          >
            <template #content>
              <span
                class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer"
                @click="toggleMonthlyVolumeModal(item)"
              >
                query_stats
              </span>
            </template> -->
          <!-- </Tooltip> -->
        </div>
      </template>
    </CustomTable>


    <CategoryKeywordsModal v-model="showCategoryModal" :mainKeyword="selectedItem"/>
    <MonthlyKeywordVolumeModal v-model="showMonthlyVolumeModal" :item="selectedItem"/>
    <MonthlyKeywordPositionsModal v-model="showMonthlyPositionModal" :item="selectedItem"/>
    <DeletedKeywordsModal v-if="showDeletedKeywordsModal" v-model="showDeletedKeywordsModal" :keywords="deletedKeywords"/>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { saveAs } from 'file-saver'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import CustomTable from '@/components/table/VTable.vue'
import DateRangePicker from '@/components/base/inputs/VDateRangePicker.vue'
import MonthlyKeywordVolumeModal from './components/keywordModals/monthlyKeywordVolumeModal.vue'
import MonthlyKeywordPositionsModal from './components/keywordModals/monthlyKeywordPositionsModal.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import Alert from '@/components/feedback/Alert.vue'
import { useAssetStore } from '@/stores/asset'
import DeletedKeywordsModal from './components/keywordModals/DeletedKeywordsModal.vue'
import CategoryKeywordsModal from './components/keywordModals/categoryKeywordModal.vue'
import IconComponent from '@/components/common/VIcon.vue'
import VSelectMenu from '@/components/base/inputs/VSelectMenu.vue'

dayjs.extend(utc)

// const items = [
//   { id: 1, text: "Seçenek 1 Seçenek 1 Seçenek 1 Seçenek 1"  },
//   { id: 2, text: "Seçenek 2" },
//   { id: 3, text: "Seçenek 3" },
// ];

// Başlangıçta hepsi seçili olması için:
// const selectedItems = ref([...items]);

const props = defineProps({
  type: {
    type: String,
    default: '1',
    validator: (value) => ['1', '2'].includes(value),
  },
  assetIdList: {
    type: Array,
    default: () => [],
  },
  isFetchable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['keywordData'])

const { t } = useI18n()
const assetStore = useAssetStore()

// State
const keywords = ref([])
const isLoading = ref(false)
const itemsPerPage = ref(30)
const totalCount = ref(0)
const pageCount = ref(0)
const currentPage = ref(1)
const showMonthlyVolumeModal = ref(false)
const showMonthlyPositionModal = ref(false)
const selectedItem = ref(null)
const datePickerModel = ref({ start: null, end: null })
const isDateChange = ref(false)
const sortKey = ref('')
const sortOrder = ref('DESC')
const searchQuery = ref('')
const selectedSourceFilter = ref('')
const excelExporting = ref(false)
const showCategoryModal = ref(false)

// Alert States
const showWarning = ref(false)
const showDeletedKeywordsModal = ref(false)
const deletedKeywords = ref([])

// Constants
const filterItems = computed(() => {
  const items = [
    {
      id: '',
      name: t('dashboard.components.keywordAnalysis.sourceType.all'),
      default: true,
    },
  ]

  if (props.type === '1') {
    items.push(
        {
          id: '1',
          name: t('dashboard.components.keywordAnalysis.sourceType.manuel'),
        },
        {
          id: '2',
          name: t('dashboard.components.keywordAnalysis.sourceType.suggestion'),
        },
    )
  } else {
    items.push({
      id: '2',
      name: t('dashboard.components.keywordAnalysis.sourceType.suggestion'),
    })
  }

  return items
})

// Dynamic Columns based on type
const dynamicColumns = computed(() => {
  const baseColumns = [
    {
      key: 'number',
      label: t('dashboard.components.keywordAnalysis.columns.number'),
      width: 'w-1/12',
    },
  ]

  // Add assetName column for type 2
  if (props.type === '2') {
    baseColumns.push({
      key: 'assetName',
      label: t('dashboard.components.keywordAnalysis.columns.assetName'),
      width: 'w-1/12',
    })
  }

  baseColumns.push(
      {
        key: 'keywordName',
        label: t('dashboard.components.keywordAnalysis.columns.keywords'),
        width: props.type === '1' ? 'w-1/12' : 'w-2/12',
      },
      {
        key: 'volume',
        label: t('dashboard.components.keywordAnalysis.columns.searchVolume'),
        width: 'w-1/12',
        align: 'center',
        sortable: true,
      },
      // {
      //   key: "source",
      //   label: t("dashboard.components.keywordAnalysis.columns.source"),
      //   width: "w-1/12",
      //   align: "center",
      // },
      // {
      //   key: "yandex",
      //   label: t("dashboard.components.keywordAnalysis.columns.yandexRank"),
      //   width: "w-1/12",
      //   align: "center",
      //   sortable: true,
      // },
      // {
      //   key: "google",
      //   label: t("dashboard.components.keywordAnalysis.columns.googleRank"),
      //   width: "w-1/12",
      //   align: "center",
      //   sortable: true,
      // },
      // {
      //   key: "bing",
      //   label: t("dashboard.components.keywordAnalysis.columns.bingRank"),
      //   width: "w-1/12",
      //   align: "center",
      //   sortable: true,
      // },
      {
        key: 'inContent',
        label: t('dashboard.components.keywordAnalysis.columns.incontent'),
        width: 'w-1/12',
        align: 'center',
        sortable: true,
      },
  )

  if (props.type === '1') {
    baseColumns.push(
        // {
        //   key: 'competitorCount',
        //   label: t('dashboard.components.keywordAnalysis.columns.competitorCount'),
        //   width: 'w-1/12',
        //   align: 'center',
        //   sortable: true,
        // },
        {
          key: 'reporting',
          label: t('dashboard.components.keywordAnalysis.columns.reporting'),
          width: 'w-1/12',
          align: 'center',
        },
    )
  }

  return baseColumns
})

const tableProps = computed(() => ({
  isLoading: isLoading.value,
  itemsPerPage: props.type === '1' ? itemsPerPage.value : keywords.value.length,
  totalCount: totalCount.value,
  pageCount: pageCount.value,
  currentPage: currentPage.value,
  sortKey: sortKey.value,
  sortOrder: sortOrder.value,
  showFilter: false,
  filterOptions: [
    {
      id: 'source',
      icon: 'filter_alt',
      iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
      showText: false,
      items: filterItems.value,
    },
  ],
  minHeight: showWarning.value ? '250px' : '300px',
  maxHeight: showWarning.value ? '250px' : '300px',
  showPagination: props.type === '1',
}))
// Methods
const fetchData = async () => {
  isLoading.value = true

  console.log("req geldi 2")

  if (props.isFetchable) {
    try {
      const params = {
        assetList: props.type === '2' ? props.assetIdList : [assetStore.assetId],
        type: props.type,
        pagination: props.type === '1' ? 1 : 0,
        ...(props.type === '1' && { page: currentPage.value - 1 }),
        ...(selectedSourceFilter.value && {
          source: selectedSourceFilter.value.source,
        }),
        ...(sortKey.value && { sortKey: sortKey.value }),
        ...(sortOrder.value && { sortOrder: sortOrder.value }),
        ...(datePickerModel.value.start &&
            datePickerModel.value.end && {
              startDate: dayjs(datePickerModel.value.start).utc().format(),
              endDate: dayjs(datePickerModel.value.end).utc().format(),
            }),
      }

      console.log("req geldi")
      const response = await axios.post(dashboard.getKeywordMainKategory, params)


      if (props.type === '1') {
        // Handle type 1 with pagination
        keywords.value = response.data.data.detail

        pageCount.value = response.data.data.pageCount
        totalCount.value = response.data.data.totalCount
      } else {
        // Handle type 2 without pagination
        keywords.value = response.data.data
        emit('keywordData', keywords.value)
        pageCount.value = 1
        totalCount.value = keywords.value.length
        currentPage.value = 1
      }

      checkWarningCondition(
          props.type === '1' ? response.data.data.deletedKeywords : [],
      )
    } catch (error) {
      console.error('Error fetching keywords:', error)
    } finally {
      isLoading.value = false
    }
  }
}

const checkWarningCondition = (data) => {
  const hasWarning = data.length > 0
  if (hasWarning) {
    showWarning.value = true
    deletedKeywords.value = data
  } else showWarning.value = false
}

const dismissWarning = () => {
  showWarning.value = false
}

const handlePageChange = (newPage) => {
  if (props.type === '1') {
    currentPage.value = newPage
    fetchData()
  }
}

const handleSort = ({ key, order }) => {
  sortKey.value = key
  sortOrder.value = order
  fetchData()
}

const handleFilter = (selectedFilter) => {
  selectedSourceFilter.value = selectedFilter
  currentPage.value = 1
  fetchData()
}

const handleSearch = (query) => {
  searchQuery.value = query
  fetchData()
}

const getMaxPositionData = (item, engineId) => {
  if (!item?.positions || !Array.isArray(item.positions)) {
    return {
      position: isDateChange.value ? 'N/A' : '100+',
      class: 'text-gray-400',
      formattedDate: t(
          'assets.dashboard.keywordAnalysis.positions.noMeasurement',
      ),
    }
  }

  const enginePositions = item.positions.filter(
      (pos) => pos.engine === engineId,
  )
  if (enginePositions.length === 0) {
    return {
      position: isDateChange.value ? 'N/A' : '100+',
      class: 'text-gray-400',
      formattedDate: t(
          'assets.dashboard.keywordAnalysis.positions.noMeasurement',
      ),
    }
  }

  const maxPosition = enginePositions.reduce(
      (max, current) =>
          parseInt(current.position) < parseInt(max.position) ? current : max,
      enginePositions[0],
  )

  const formattedDate = maxPosition.addDate
      ? dayjs(maxPosition.addDate).format('YYYY-MM-DD')
      : ''
  let position =
      maxPosition.position === '0' ? '100+' : maxPosition.position || 'N/A'
  const positionDifference = parseInt(maxPosition.positionDifference) || 0
  const numericPosition = parseInt(position)

  if (position === 'N/A' || numericPosition > 100) {
    position = '100+'
  } else if (isDateChange.value) {
    const sign = positionDifference > 0 ? '+' : ''
    position += ` (${sign}${positionDifference})`
  }

  // Type 2 için özel stil sınıfı belirleme
  let positionClass = 'text-gray-700'
  if (props.type === '2') {
    if (numericPosition === 1) {
      positionClass = 'text-primary font-bold' // 1. pozisyon için özel stil
    } else if (position === '100+') {
      positionClass = 'text-gray-400'
    } else if (isDateChange.value) {
      positionClass =
          positionDifference > 0
              ? 'text-green-500 font-bold'
              : positionDifference < 0
                  ? 'text-red-500 font-bold'
                  : 'text-gray-700'
    }
  } else {
    // Mevcut mantık type 1 için
    if (position === '100+') {
      positionClass = 'text-gray-400'
    } else if (isDateChange.value) {
      positionClass =
          positionDifference > 0
              ? 'text-green-500 font-bold'
              : positionDifference < 0
                  ? 'text-red-500 font-bold'
                  : 'text-gray-700'
    }
  }

  const tooltipText = formattedDate
      ? t('assets.dashboard.keywordAnalysis.positions.lastMeasurement', {
        date: formattedDate,
      })
      : ''

  return { position, class: positionClass, formattedDate: tooltipText }
}

const getVolumeDisplay = (item) => {
  const volume = item.volume || 0
  const volumeChangePercentage = parseFloat(item.volumeChangePercentage) || 0

  let displayText = volume.toString()
  let fullText = displayText
  let volumeClass = 'text-sm'

  if (isDateChange.value && volumeChangePercentage !== 0) {
    const sign = volumeChangePercentage > 0 ? '+' : ''
    const changeText = `(${sign}${volumeChangePercentage}%)`
    fullText = `${displayText} ${changeText}`
    displayText = fullText

    volumeClass +=
        volumeChangePercentage > 0 ? ' text-green-500' : ' text-red-500'
  }

  if (fullText.length > 12) {
    volumeClass += ' text-xs'
  }

  return {
    displayText,
    fullText,
    class: volumeClass,
  }
}


const exportToExcel = () => {
  excelExporting.value = true
  const startDate = datePickerModel?.value?.start
      ? dayjs(datePickerModel.value.start).utc().format()
      : dayjs().startOf('year').utc().format()

  const endDate = datePickerModel?.value?.end
      ? dayjs(datePickerModel.value.end).utc().format()
      : dayjs().utc().format()

  const params = {
    assetList: [assetStore.assetId],
    startDate,
    endDate,
  }

  axios
      .post(dashboard.exportKeywords, params, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type:
              response.headers['content-type'] ||
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileName = `keyword_analysis_${dayjs().format('YYYY-MM-DD')}.xlsx`
        saveAs(blob, fileName)
      })
      .catch((error) => console.error('Export failed:', error))
      .finally(() => {
        excelExporting.value = false
      })
}

const categoryKeywordModal = (item) => {
  selectedItem.value = { id: item.id, name: item.keywordName }
  showCategoryModal.value = true
}


const calculateRank = (index) =>
    (currentPage.value - 1) * itemsPerPage.value + index + 1

// Watch date changes
watch(datePickerModel, (newValue, oldValue) => {
  if (newValue.start !== oldValue.start || newValue.end !== oldValue.end) {
    if (newValue.start && newValue.end) {
      currentPage.value = 1
      isDateChange.value = true
      fetchData()
    } else if (newValue.start === null && newValue.end === null) {
      isDateChange.value = false
      fetchData()
    }
  }
})


fetchData();

watch(
    () => props.isFetchable,
    () => {
      fetchData()
    },
)
</script>
