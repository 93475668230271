<template>
  <div class="space-y-4">
    <!-- Current Level Category -->
    <div class="relative flex items-start gap-4 group" :class="levelStyles">
      <!-- Branching Lines -->
      <template v-if="props.currentLevel > 1">
        <div class="absolute -left-4 top-0 w-px h-full bg-gray-200"></div>
        <div class="absolute -left-4 top-1/2 w-4 h-px bg-gray-200"></div>
      </template>

      <div class="flex-grow">
        <!-- Category Card -->
        <div class="relative flex items-center gap-3 bg-white border rounded-lg p-3 hover:border-primary/30 transition-all duration-300">
          <!-- Level Indicator / Icon -->
          <div class="flex items-center">
            <span class="material-symbols-outlined" :class="levelIcon">
              {{ props.currentLevel === 1 ? 'category' : 'subdirectory_arrow_right' }}
            </span>
          </div>

          <div class="flex-grow">
            <!-- Parent Category Indicator -->
            <div v-if="props.currentLevel > 1 && props.parentName" class="text-xs text-gray-400 mb-1 flex items-center gap-1">
              <span class="material-symbols-outlined text-xs">arrow_right</span> {{ props.parentName }}
            </div>

            <!-- Category Input -->
            <input v-model="categoryModel.name" type="text" class="w-full bg-transparent border-none focus:outline-none text-gray-700 placeholder-gray-400" :placeholder="t('settings.components.configuration.components.categoryModal.level.categoryName', { level: props.currentLevel })" @input="validateCategory"/>
          </div>

          <!-- Actions -->
          <div class="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
            <!-- Add Subcategory -->
            <button v-if="props.currentLevel < props.maxLevel" @click="addChild" class="p-2 rounded-lg hover:bg-primary/10 text-gray-400 hover:text-primary transition-colors duration-300 flex items-center gap-1" :title="t('settings.components.configuration.components.categoryModal.level.addSubCategory')">
              <span class="material-symbols-outlined text-sm">add_circle</span> <span class="text-xs">{{ t('settings.components.configuration.components.categoryModal.level.addSubCategory') }}</span>
            </button>

            <!-- Delete -->
            <button @click="$emit('remove')" class="p-2 rounded-lg hover:bg-red-50 text-gray-400 hover:text-red-500 transition-colors duration-300" :title="t('settings.components.configuration.components.categoryModal.level.deleteCategory')">
              <span class="material-symbols-outlined">delete</span>
            </button>
          </div>
        </div>

        <!-- Error Message -->
        <span v-if="categoryModel.error" class="text-xs text-red-500 mt-1">
          {{ categoryModel.error }}
        </span>
      </div>
    </div>

    <!-- Subcategories -->
    <div v-if="categoryModel.children?.length" class="space-y-4 ml-8 relative">
      <!-- Subcategory Branch Line -->
      <div class="absolute left-0 top-0 w-px h-full bg-gray-200"></div>

      <CategoryLevel v-for="(child, index) in categoryModel.children" :key="index" v-model:category="categoryModel.children[index]" :current-level="props.currentLevel + 1" :max-level="props.maxLevel" :parent-name="categoryModel.name" @remove="removeChild(index)"/>
    </div>

    <!-- Add Subcategory Button (Bottom) -->
    <button v-if="props.currentLevel < props.maxLevel" @click="addChild" class="ml-8 py-2 px-3 text-sm border border-dashed border-gray-200 rounded-lg text-gray-400 hover:text-primary hover:border-primary/30 hover:bg-primary/5 transition-all duration-300 flex items-center gap-2">
      <span class="material-symbols-outlined text-sm">add_circle</span> <span>{{ t('settings.components.configuration.components.categoryModal.level.addSubCategory') }}</span>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  category: {
    type: Object,
    required: true,
  },
  currentLevel: {
    type: Number,
    required: true,
  },
  maxLevel: {
    type: Number,
    required: true,
  },
  parentName: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:category', 'remove'])

const categoryModel = computed({
  get: () => props.category,
  set: (value) => emit('update:category', value),
})

const levelStyles = computed(() => ({
  'ml-8': props.currentLevel > 1,
  [`pl-${props.currentLevel * 2}`]: props.currentLevel > 1,
}))

const levelIcon = computed(() => {
  const opacity = 1 - (props.currentLevel - 1) * 0.2
  return `text-primary opacity-${Math.max(opacity * 100, 30)}`
})

const validateCategory = () => {
  if (!categoryModel.value.name?.trim()) {
    categoryModel.value.error = t('settings.components.configuration.components.categoryModal.level.validation.required')
    return false
  }

  if (categoryModel.value.name.length > 50) {
    categoryModel.value.error = t('settings.components.configuration.components.categoryModal.level.validation.maxLength')
    return false
  }

  categoryModel.value.error = ''
  return true
}

const addChild = () => {
  if (!categoryModel.value.children) {
    categoryModel.value.children = []
  }

  // Sadece maxLevel kontrolü yapılıyor
  if (props.currentLevel >= props.maxLevel) return

  categoryModel.value.children.push({
    name: '',
    level: props.currentLevel + 1,
    children: [],
    parentName: categoryModel.value.name,
  })
}

const removeChild = (index) => {
  categoryModel.value.children.splice(index, 1)
}
</script>

<style scoped>
.nested-select-enter-active,
.nested-select-leave-active {
  transition: all 0.3s ease;
}

.nested-select-enter-from,
.nested-select-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>