<template>
  <div class="bg-white rounded-md p-4 w-full">
    <div class="flex items-center justify-between">
      <div class="flex-grow">
        <span class="flex items-center space-x-1">
          <h3 class="text-sm font-semibold mb-1">{{ title }}</h3>
          <VTooltip v-if="information" :title="information" information>
            <template #content>
              <icon-component
                icon="info"
                iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
              />
            </template>
          </VTooltip>
        </span>
        <p class="text-xs mb-2 text-gray-500">{{ description }}</p>
      </div>
      <CustomButton
        :button-text="buttonText || t('general.view')"
        @click="$emit('view')"
        :size="buttonSize"
        :is-loading="isLoading"
        :loading-text="loadingText"
        :is-disabled="isDisabled"
        :disable-gray="disableGray"
        :show-tooltip="showTooltip"
        :tooltip-text="tooltipText"
        :tooltip-color="tooltipColor"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import CustomButton from "@/components/base/buttons/VButton.vue";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const { t } = useI18n();

defineProps({
  // Base props
  information: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },

  // Button props
  buttonText: {
    type: String,
    default: "",
  },
  buttonSize: {
    type: String,
    default: "sm",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  loadingText: {
    type: String,
    default: "",
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  disableGray: {
    type: Boolean,
    default: false,
  },

  // Tooltip props
  showTooltip: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: "",
  },
  tooltipColor: {
    type: String,
    default: "primary",
  },
});

defineEmits(["view"]);
</script>
