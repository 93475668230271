<template>
  <component :is="currentStepComponent" v-bind="stepProps" ref="stepRef" />
</template>

<script setup>
import { computed, markRaw, defineAsyncComponent, ref, watch } from "vue";
import { setupSteps } from "./config";
import { useSetupStore } from "@/stores/assetSetup";
import { useStepManager } from "@/composables/useStepManager";
const assetSetup = useSetupStore();
const stepManager = useStepManager();
const emit = defineEmits(["stepComplete"]);
const props = defineProps({
  step: {
    type: String,
    required: true,
    validator: (value) => !!setupSteps[value],
  },
});
const stepRef = ref(null);

const currentStepComponent = computed(() => {
  const component = setupSteps[props.step].component;
  return component ? markRaw(defineAsyncComponent(component)) : null;
});

const stepProps = computed(() => ({
  stepSlug: props.step,
  stepOrder: setupSteps[props.step].order,
}));
watch(
  () => assetSetup.nextStep,
  async () => {
    if (stepRef.value?.save) {
      const isValid = await stepManager.goToNextStep(stepRef);
      emit("stepComplete", isValid);
    }
  }
);
</script>
