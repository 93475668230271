<template>
  <VueFinalModal :click-to-close="!isLoading" :esc-to-close="!isLoading" @opened="handleModalOpened" @closed="handleModalClosed" class="flex justify-center items-center" content-class="flex flex-col bg-white rounded-md shadow-lg w-full md:w-[90vw] md:max-w-5xl h-[90vh] md:h-[75vh] overflow-hidden">
    <div class="h-full flex flex-col">
      <!-- Header -->
      <header class="bg-gradient-to-r from-primary/5 to-transparent border-b backdrop-blur-sm px-6 py-4 flex items-center justify-between">
        <h2 class="text-xl font-bold text-gray-800 flex items-center gap-2">
          <span class="material-symbols-outlined text-primary">category</span> {{
            t(`settings.components.configuration.components.categoryModal.${currentView === 'list' ? 'list.title' : 'creator.title'}`)
          }} </h2>
        <button @click="closeModal" class="text-gray-500 hover:text-gray-700 flex items-center" :title="t('settings.components.configuration.components.categoryModal.common.close')">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <!-- Main Content -->
      <div class="flex-grow flex flex-col overflow-hidden">
        <!-- List View -->
        <CategoryList v-if="currentView === 'list'" :categories="categories" :sub-categories="subCategories" :is-loading="isLoading" :expanded-categories="expandedCategories" :loading-sub-categories="loadingSubCategories" @toggle-category="handleToggleCategory" @category-deleted="handleCategoryDeleted" @add-new="currentView = 'create'"/>

        <!-- Create View -->
        <CategoryCreator v-else :is-submitting="isSubmitting" @save="handleSaveCategories" @cancel="currentView = 'list'"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { useAssetStore } from '@/stores/asset'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CategoryList from './categoryList.vue'
import CategoryCreator from './categoryCreator.vue'

const { t } = useI18n()
const assetStore = useAssetStore()

// Emits
const emit = defineEmits(['update:show'])

// States
const currentView = ref('list')
const isLoading = ref(false)
const isSubmitting = ref(false)
const loadingSubCategories = ref(null)

const categories = ref([])
const subCategories = ref({})
const expandedCategories = ref([])

// Ana kategori verilerini getir
const fetchCategories = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(settings.keywordCategories, {
      params: {
        assetId: assetStore.assetId,
        level: 1,
        isActive: 1,
        isCustom: true,
      },
    })

    if (response?.data?.data?.detail) {
      categories.value = response.data.data.detail
    }
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.fetchCategories'))
    categories.value = []
  } finally {
    isLoading.value = false
  }
}

// Alt kategorileri getir
const fetchSubCategories = async ({ parentId }) => {
  if (subCategories.value[parentId]) return

  loadingSubCategories.value = parentId
  try {
    const response = await axios.get(settings.subcategories, {
      params: {
        parentId,
        assetId: assetStore.assetId,
        isCustom: true,
      },
    })

    if (response?.data?.data?.detail) {
      subCategories.value[parentId] = response.data.data.detail
    }
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.fetchSubcategories'))
    subCategories.value[parentId] = []
  } finally {
    loadingSubCategories.value = null
  }
}

// Kategori genişlet/daralt
const handleToggleCategory = async ({ id, isLevel1 }) => {
  if (isLevel1) {
    const otherLevel1Expanded = expandedCategories.value.filter(categoryId => {
      const category = categories.value.find(c => c.id === categoryId)
      return category?.level === 1 && categoryId !== id
    })

    otherLevel1Expanded.forEach(categoryId => {
      delete subCategories.value[categoryId]
    })

    expandedCategories.value = expandedCategories.value.filter(categoryId => {
      const category = categories.value.find(c => c.id === categoryId)
      return category?.level !== 1 || categoryId === id
    })
  }

  const index = expandedCategories.value.indexOf(id)
  if (index === -1) {
    expandedCategories.value.push(id)
    await fetchSubCategories({ parentId: id })
  } else {
    expandedCategories.value.splice(index, 1)
    delete subCategories.value[id]
  }
}

// Kategori silindi
const handleCategoryDeleted = async (categoryInfo) => {
  delete subCategories.value[categoryInfo.id]

  if (categoryInfo.parentId) {
    delete subCategories.value[categoryInfo.parentId]

    if (expandedCategories.value.includes(categoryInfo.parentId)) {
      await fetchSubCategories({
        parentId: categoryInfo.parentId,
      })
    }
  }

  expandedCategories.value = expandedCategories.value.filter(
      id => id !== categoryInfo.id,
  )

  await fetchCategories()
}

// Yeni kategorileri kaydet
const handleSaveCategories = async (categoryData) => {
  isSubmitting.value = true
  try {
    const payload = {
      assetId: assetStore.assetId,
      categories: categoryData,
    }

    await axios.post(settings.customCategories, payload)
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.categoryModal.common.success.created'))

    currentView.value = 'list'
    await fetchCategories()
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isSubmitting.value = false
  }
}

const closeModal = () => {
  emit('update:show', false)
}

// Lifecycle metodları
const handleModalOpened = () => {
  fetchCategories()
}

const handleModalClosed = () => {
  currentView.value = 'list'
  categories.value = []
  subCategories.value = {}
  expandedCategories.value = []
}

// Watchers
watch(() => assetStore.assetId, () => {
  fetchCategories()
})
</script>