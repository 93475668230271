import { ref } from "vue";

export function useStepManager() {
  const currentStepData = ref({
    step: null,
    data: null,
    isValid: false,
  });

  const goToNextStep = async (stepRef) => {
    if (!stepRef.value?.save) return false;
    const result = await stepRef.value.save();
    if (result.isValid) {
      currentStepData.value = {
        ...currentStepData.value,
        step: result.step,
        data: result.data,
      };
    }
    return result.isValid;
  };

  return {
    currentStepData,
    goToNextStep,
  };
}
