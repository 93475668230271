/**
 * Temel Veri İşleme Sınıfı
 */
class KeywordBatchIterator {
  constructor(keywords, batchSize = 1000) {
    this.keywords = keywords
    this.batchSize = batchSize
    this.currentIndex = 0
  }

  * [Symbol.iterator]() {
    while (this.currentIndex < this.keywords.length) {
      const batch = this.keywords.slice(
          this.currentIndex,
          this.currentIndex + this.batchSize,
      )
      this.currentIndex += this.batchSize
      yield batch
    }
  }

  reset() {
    this.currentIndex = 0
  }
}

export const KeywordUtils = {
  /**
   * 1. DUPLICATE KONTROL VE İŞLEME FONKSİYONLARI
   */
  handleDuplicateKeywords: (keywords, { batchSize = 1000, enableLogging = false } = {}) => {
    if (!keywords?.length) return

    try {
      const state = {
        groups: new Map(),
        nullCount: 0,
        dupCount: 0,
        processed: 0,
      }

      KeywordUtils.processAllKeywords(keywords, batchSize, state, enableLogging)

      if (enableLogging) {
        KeywordUtils.logSummary(state.nullCount, state.dupCount)
      }
    } catch (error) {
      console.error('Anahtar kelime işleme hatası:', error)
      throw error
    }
  },

  processAllKeywords: (keywords, batchSize, state, enableLogging) => {
    const iterator = new KeywordBatchIterator(keywords, batchSize)

    for (const batch of iterator) {
      KeywordUtils.processBatch(batch, state)
      if (enableLogging) console.log(`İşlenen: ${state.processed}/${keywords.length}`)
    }

    KeywordUtils.handleDuplicateGroups(state)
  },

  processBatch: (batch, state) => {
    batch.forEach(kw => {
      if (!kw.name) {
        kw.state = 3
        state.nullCount++
        return
      }
      KeywordUtils.addToGroups(kw, state.groups)
    })
    state.processed += batch.length
  },

  addToGroups: (keyword, groups) => {
    if (!groups.has(keyword.name)) {
      groups.set(keyword.name, [])
    }
    groups.get(keyword.name).push(keyword)
  },

  handleDuplicateGroups: (state) => {
    state.groups.forEach(group => {
      if (group.length > 1) {
        state.dupCount++
        KeywordUtils.markDuplicates(group)
      }
    })
    state.groups.clear()
  },

  markDuplicates: (group) => {
    const minId = Math.min(...group.map(k => k.id))
    group.forEach(kw => {
      if (kw.id !== minId) kw.state = 3
    })
  },

  /**
   * 2. METİN İŞLEME VE NORMALIZATION FONKSİYONLARI
   */
  normalize: (keyword) => {
    if (!keyword) return ''
    return keyword
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ')
        .replace(/[.,\/#!$%^&*;:{}=\-_`~()]/g, '')
  },

  areSimilar: (str1, str2) => {
    const normalized1 = KeywordUtils.normalize(str1)
    const normalized2 = KeywordUtils.normalize(str2)
    return normalized1 === normalized2
  },

  /**
   * 3. ARAMA VE FİLTRELEME FONKSİYONLARI
   */
  findSimilarInArray: (keyword, array) => {
    const normalizedKeyword = KeywordUtils.normalize(keyword)
    return array.find((item) =>
        KeywordUtils.normalize(item.name) === normalizedKeyword,
    )
  },

  findAllSimilarInArray: (keyword, array) => {
    const normalizedKeyword = KeywordUtils.normalize(keyword)
    return array.filter((item) =>
        KeywordUtils.normalize(item.name) === normalizedKeyword,
    )
  },

  /**
   * 4. EXCEL İŞLEMLERİ VE DOĞRULAMA
   */
  validateImportedKeywords: (keywords, existingKeywords, maxKeywords) => {
    const results = {
      valid: [],
      duplicates: [],
      invalid: [],
      limitExceeded: [],
    }

    const processedKeywords = new Map()
    let processedCount = 0

    keywords.forEach((keyword) => {
      if (!keyword.name?.trim()) {
        results.invalid.push({ keyword, reason: 'empty' })
        return
      }

      const normalizedKeyword = KeywordUtils.normalize(keyword.name)

      if (processedKeywords.has(normalizedKeyword)) {
        results.duplicates.push({
          keyword,
          existingKeyword: processedKeywords.get(normalizedKeyword),
        })
        return
      }

      if (processedCount >= maxKeywords) {
        results.limitExceeded.push(keyword)
        return
      }

      const similarKeyword = KeywordUtils.findSimilarInArray(
          normalizedKeyword,
          existingKeywords.filter((k) => k.state !== 3),
      )

      if (similarKeyword) {
        results.duplicates.push({
          keyword,
          existingKeyword: similarKeyword,
        })
        return
      }

      results.valid.push(keyword)
      processedKeywords.set(normalizedKeyword, keyword)
      processedCount++
    })

    return results
  },

  /**
   * 5. LOGLAMA FONKSİYONLARI
   */
  logSummary: (nullCount, dupCount) => {
    if (nullCount) {
      console.log(`\n${nullCount} adet null name'li keyword state=3 yapıldı.`)
    }

    if (dupCount) {
      console.log(`\nToplam ${dupCount} adet tekrarlanan keyword grubu bulundu.`)
    } else {
      console.log('Tekrarlanan keyword bulunamadı.')
    }
  },
}