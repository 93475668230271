/*
# TODO: Excel export error handling geliştirilmeli
- Export ve template download hatalarında kullanıcıya bilgi vermek için `responseHandlerService` kullanılabilir.
- Hata durumlarında retry mekanizması eklenebilir.

# TODO: Büyük veri setleri için performans optimizasyonu yapılmalı
- Çok sayıda keyword olduğunda batch processing yapılabilir.
- Memory optimizasyonu için veriler chunk'lar halinde işlenebilir.
*/

import { ref } from 'vue'
import ExcelJS from 'exceljs'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'

export function useKeywordExport(keywordsTableData, t) {
  const isExporting = ref(false)

  const setupWorksheet = (worksheet) => {
    worksheet.columns = [
      { header: 'Keyword', key: 'keyword', width: 30 },
      { header: 'Priority Score (1/10)', key: 'priorityScore', width: 15 },
    ]

    worksheet.getRow(1).font = { bold: true }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE9ECEF' },
    }
  }

  const applyWorksheetStyling = (worksheet) => {
    worksheet.eachRow((row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFE9ECEF' } },
          left: { style: 'thin', color: { argb: 'FFE9ECEF' } },
          bottom: { style: 'thin', color: { argb: 'FFE9ECEF' } },
          right: { style: 'thin', color: { argb: 'FFE9ECEF' } },
        }
      })
    })
  }

  const downloadTemplate = async () => {
    try {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Keywords')

      setupWorksheet(worksheet)

      const sampleData = [
        { keyword: 'keyword 1', priorityScore: '1' },
        { keyword: 'keyword 2', priorityScore: '2' },
      ]

      sampleData.forEach((item, index) => {
        worksheet.addRow({
          keyword: item.keyword,
          priorityScore: item.priorityScore,
        })

        if (index % 2 === 1) {
          worksheet.getRow(index + 2).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF8F9FA' },
          }
        }
      })

      applyWorksheetStyling(worksheet)

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const fileName = `keywords_template.xlsx`
      saveAs(blob, fileName)

      return { success: true }
    } catch (error) {
      console.error('Template download failed:', error)
      return { success: false, error }
    }
  }

  const exportKeywords = async () => {
    if (isExporting.value) return

    isExporting.value = true
    try {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Keywords')

      setupWorksheet(worksheet)

      keywordsTableData.value
          .filter((k) => k.state !== 3)
          .sort((a, b) => {
            if (a.priorityScore !== b.priorityScore) {
              return (b.priorityScore || 0) - (a.priorityScore || 0)
            }
            return a.name.localeCompare(b.name)
          })
          .forEach((keyword, index) => {
            worksheet.addRow({
              keyword: keyword.name,
              priorityScore: keyword.priorityScore || '',
            })

            if (index % 2 === 1) {
              worksheet.getRow(index + 2).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFF8F9FA' },
              }
            }
          })

      applyWorksheetStyling(worksheet)

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const fileName = `keywords_${dayjs().format('YYYY-MM-DD')}.xlsx`
      saveAs(blob, fileName)

      return { success: true }
    } catch (error) {
      console.error('Export failed:', error)
      return { success: false, error }
    } finally {
      isExporting.value = false
    }
  }

  return {
    isExporting,
    exportKeywords,
    downloadTemplate,
  }
}