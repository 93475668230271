
export const setupSteps = {
  information: {
    id: "information",
    order: 1,
    title: "settings.components.setups.information.title",
    description: "settings.components.setups.information.description",
    component: () => import("./steps/Information.vue"),
    nextStep: "general",
  },
  general: {
    id: "general",
    order: 2,
    title: "settings.components.setups.general.title",
    description: "settings.components.setups.general.description",
    component: () => import("./steps/GeneralSettings.vue"),
    nextStep: "configuration",
  },
  configuration: {
    id: "configuration",
    order: 3,
    title: "settings.components.setups.configuration.title",
    description: "settings.components.setups.configuration.description",
    component: () => import("./steps/Configuration.vue"),
    nextStep: "integrations",
  },
  integrations: {
    id: "integrations",
    order: 4,
     title: "settings.components.setups.integrations.title",
    description: "settings.components.setups.integrations.description",
    component: () => import("./steps/Integrations.vue"),
    nextStep: "schedule",
  },
  schedule: {
    id: "schedule",
    order: 5,
     title: "settings.components.setups.schedule.title",
    description: "settings.components.setups.schedule.description",
    component: () => import("./steps/Schedule.vue"),
    nextStep: "completed",
  },
  completed: {
    id: "completed",
    order: 6,
     title: "settings.components.setups.completed.title",
    description: "settings.components.setups.completed.description",
    component: () => import("./steps/Completed.vue"),
    nextStep: null,
  },
};
// Yardımcı fonksiyonlar
export const getFirstStep = () => {
  return Object.values(setupSteps).sort((a, b) => a.order - b.order)[0].id;
};

export const getNextStep = (currentStepId) => {
  const currentStep = setupSteps[currentStepId];
  return currentStep?.nextStep;
};

export const getPreviousStep = (currentStepId) => {
  const currentOrder = setupSteps[currentStepId].order;
  return Object.values(setupSteps).find(
    (step) => step.order === currentOrder - 1
  )?.id;
};

export const isValidStep = (stepId) => {
  return stepId in setupSteps;
};

export const getStepTitle = (stepId) => {
  return setupSteps[stepId]?.title || "";
};

export const getStepDescription = (stepId) => {
  return setupSteps[stepId]?.description || "";
};

export const getStepOrder = (stepId) => {
  return setupSteps[stepId]?.order || 0;
};

export const getTotalSteps = () => {
  return Object.keys(setupSteps).length;
};

export const isLastStep = (stepId) => {
  return setupSteps[stepId]?.nextStep === null;
};
