import { ref, computed } from 'vue'
import { KeywordUtils } from '@/utils/keywordUtils'
import { responseHandlerService } from '@/plugins/responseHandlerService'

export function useKeywords(props, emit, t) {
  const selectedKeywords = ref([])
  const selectedKeyword = ref(null)
  const showEditModal = ref(false)

  const keywordsTableData = computed(() => {
    const keywordMap = new Map()

    props.keywords
        .filter((keyword) => keyword.state !== 3)
        .forEach((keyword) => {
          const normalizedName = KeywordUtils.normalize(keyword.name)
          const existing = keywordMap.get(normalizedName)

          if (
              !existing ||
              keyword.state > existing.state ||
              (keyword.state === existing.state &&
                  (keyword.id > existing.id ||
                      keyword.keywordId > existing.keywordId))
          ) {
            keywordMap.set(normalizedName, keyword)
          }
        })

    return Array.from(keywordMap.values())
        .map((keyword) => ({
          id: keyword.id || keyword.keywordId,
          keywordId: keyword.keywordId,
          name: keyword.name || '',
          state: keyword.state,
          priority: keyword.priority,
          categoryId: keyword.categoryId,
          isNew: keyword.state === 2,
        }))
        .sort((a, b) => {
          if (a.priority !== b.priority)
            return (b.priority || 0) - (a.priority || 0)
          const nameA = a.name || ''
          const nameB = b.name || ''
          return nameA.localeCompare(nameB)
        })
  })

  const activeKeywordsCount = computed(() => keywordsTableData.value.length)

  const handleSelectionChange = (newSelection) => {
    selectedKeywords.value = newSelection
  }

  const handleBulkDelete = () => {
    if (!selectedKeywords.value.length) return
    const updatedKeywords = props.keywords
        .map((k) => {
          const isSelected = selectedKeywords.value.some(
              (selected) =>
                  selected === k.id ||
                  selected === k.keywordId ||
                  selected.name === k.name,
          )

          if (isSelected) {
            if (k.state === 2) {
              return null
            }
            return { ...k, state: 3, priority: null, categoryId: null }
          }
          return k
        })
        .filter((k) => k !== null)

    emit('update:keywords', updatedKeywords)

    responseHandlerService.successHandler(
        t(
            'settings.components.configuration.components.keywordsTable.bulkDeleteSuccess',
            { count: selectedKeywords.value.length },
        ),
    )

    selectedKeywords.value = []
  }

  const handleEdit = (keyword) => {
    selectedKeyword.value = {
      ...keyword,
      categoryId: keyword.categoryId || null,
    }
    showEditModal.value = true
  }

  const handleEditKeyword = (editedKeyword) => {
    const similarKeyword = KeywordUtils.findSimilarInArray(
        editedKeyword.name,
        props.keywords.filter(
            (k) =>
                k.state !== 3 &&
                ((k.id && k.id !== editedKeyword.id) ||
                    (k.keywordId && k.keywordId !== editedKeyword.keywordId) ||
                    (k.state === 2 && k.name !== editedKeyword.name)),
        ),
    )

    if (similarKeyword) {
      responseHandlerService.errorHandler(
          t(
              'settings.components.configuration.components.keywordsTable.duplicateKeyword',
          ),
      )
      return
    }

    const updatedKeywords = props.keywords.map((k) => {
      if (
          (k.id && k.id === editedKeyword.id) ||
          (k.keywordId && k.keywordId === editedKeyword.keywordId) ||
          (k.state === 2 && k.name === editedKeyword.name)
      ) {
        const isChanged =
            k.name !== editedKeyword.name ||
            k.priority !== editedKeyword.priority ||
            k.categoryId !== editedKeyword.categoryId

        return {
          ...k,
          name: editedKeyword.name.trim(),
          priority: editedKeyword.priority,
          categoryId: editedKeyword.categoryId,
          state: k.state === 1 && isChanged ? 4 : k.state,
        }
      }
      return k
    })

    emit('update:keywords', updatedKeywords)
    responseHandlerService.successHandler(
        t(
            'settings.components.configuration.components.keywordsTable.keywordUpdated',
            {
              keyword: editedKeyword.name,
            },
        ),
    )
    showEditModal.value = false
  }

  const handleAddKeywords = (newKeywords) => {
    if (activeKeywordsCount.value + newKeywords.length > props.maxKeywords) {
      responseHandlerService.errorHandler(
          t(
              'settings.components.configuration.components.keywordsTable.keywordLimitExceeded',
              {
                max: props.maxKeywords,
              },
          ),
      )
      return
    }

    const invalidKeywords = newKeywords.filter((k) => k.name.trim().length < 2)
    if (invalidKeywords.length > 0) {
      responseHandlerService.errorHandler(t('settings.components.configuration.validation.keyword.minLength'))
      return
    }

    const updatedKeywords = [...props.keywords]
    const results = { new: 0, reactivated: 0, updated: 0 }

    newKeywords.forEach((newKeyword) => {
      const existingKeyword = updatedKeywords
          .filter((k) => k.state !== 3)
          .find((k) => k.name.trim() === newKeyword.name.trim())

      if (existingKeyword) {
        results.unchanged++
      } else {
        updatedKeywords.push({
          name: newKeyword.name.trim(),
          priority: newKeyword.priority,
          categoryId: newKeyword.categoryId,
          state: 2,
        })
        results.new++
      }
    })

    if (results.new > 0) {
      emit('update:keywords', updatedKeywords)
      Object.entries(results).forEach(([type, count]) => {
        if (count > 0 && type !== 'unchanged') {
          responseHandlerService.successHandler(
              t(
                  `settings.components.configuration.components.keywordsTable.keywords${type.charAt(0).toUpperCase() + type.slice(1)}`,
                  { count },
              ),
          )
        }
      })
    }
  }

  const removeKeyword = (keyword) => {
    try {
      const updatedKeywords = [...props.keywords]
      const keywordName = typeof keyword === 'string' ? keyword : keyword.name

      const keywordIndex = updatedKeywords.findIndex((k) => {
        if (!k || !keyword) return false

        return (
            k.name.toLowerCase() === keyword.name.toLowerCase() &&
            String(k.id) === String(keyword.id) &&
            String(k.keywordId) === String(keyword.keywordId)
        )
      })
      if (keywordIndex === -1) {
        throw new Error(`Keyword not found: ${keywordName}`)
      }
      updatedKeywords[keywordIndex] = {
        ...updatedKeywords[keywordIndex],
        state: 3,
        categoryId: null,
      }
      emit('update:keywords', updatedKeywords)
      responseHandlerService.successHandler(
          t(
              'settings.components.configuration.components.keywordsTable.keywordDeleted',
              {
                keyword: keywordName,
              },
          ),
      )
    } catch (error) {
      console.error('Error removing keyword:', error)
      responseHandlerService.errorHandler(
          t(
              'settings.components.configuration.components.keywordsTable.deleteError',
              {
                keyword: typeof keyword === 'string' ? keyword : keyword.name,
              },
          ),
      )
    }
  }

  const resetSelection = () => {
    selectedKeywords.value = []
    showEditModal.value = false
    selectedKeyword.value = null
  }

  return {
    selectedKeywords,
    selectedKeyword,
    showEditModal,
    keywordsTableData,
    activeKeywordsCount,
    handleSelectionChange,
    handleBulkDelete,
    handleEdit,
    handleEditKeyword,
    handleAddKeywords,
    removeKeyword,
    resetSelection,
  }
}