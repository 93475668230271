<template>
  <div class="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden h-full flex flex-col relative group" :class="cardClasses" @click="handleCardClick" @mousedown="handleMouseDown" @mouseenter="isHovered = true" @mouseleave="isHovered = false" @contextmenu.prevent="handleRightClick">
    <div v-if="!isNewAssetCard" class="p-4 flex-grow">
      <div class="flex items-center justify-between mb-3">
        <img v-if="logo" :src="logo" :alt="name" class="h-6 w-6 object-contain"/>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-semibold text-gray-800 truncate flex items-center" :title="trimmedName">
          <svg-icon name="document" class="w-4 h-4 mr-1 text-gray-500"/>
          {{ trimmedName }}
        </h3>
        <p class="text-xs text-gray-600 truncate flex items-center" :title="website">
          <svg-icon name="globe" class="w-4 h-4 mr-1 text-gray-500"/>
          {{ website }}
        </p>
        <div class="flex items-center justify-between mt-2">
          <p class="text-xs truncate flex items-center">
            <svg-icon name="website" class="w-4 h-4 mr-1 text-gray-500/40"/>
            <svg-icon name="facebook" v-if="isFacebook" class="w-4 h-4 mr-1 text-gray-500/40"/>
          </p>
          <Tooltip :title="statusTooltipText" :display-condition="isSetupProcess && !showCustomMenu" :external-trigger="true" :is-triggered="isHovered">
            <template #content>
              <div class="text-xs font-semibold py-1 px-2 rounded-full flex items-center" :class="statusClass" @click="handleStatusClick">
                <svg-icon :name="statusIcon" class="w-4 h-4 mr-1"/>
                {{ statusText }}
              </div>
            </template>
          </Tooltip>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <div v-if="showCustomMenu" class="fixed bg-white shadow-lg border border-gray-100 rounded-md z-50 custom-menu" :style="customMenuPosition" @click.stop>
        <ul class="py-1">
          <li v-if="isSetupProcess" class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm group">
            <a @click.prevent="handleMenuItemClick('continue')" href="#" class="flex items-center text-gray-700 group-hover:text-gray-900">
              <svg-icon name="arrow-right" class="w-4 h-4 mr-2 text-gray-500"/>
              {{ t('assets.components.card.menu.continueSetup') }} </a>
          </li>
          <li v-if="!isSetupProcess && !analysisState" class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm group">
            <a @click.prevent="handleMenuItemClick('open')" href="#" class="flex items-center text-gray-700 group-hover:text-gray-900">
              <svg-icon name="external-link" class="w-4 h-4 mr-2 text-gray-500"/>
              {{ t('assets.components.card.menu.open') }} </a>
          </li>
          <li v-if="analysisState" class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm group">
            <a @click.prevent="handleMenuItemClick('edit')" href="#" class="flex items-center text-gray-700 group-hover:text-gray-900">
              <svg-icon name="pencil" class="w-4 h-4 mr-2 text-gray-500"/>
              {{ t('assets.components.card.menu.edit') }} </a>
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, inject, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAssetStore } from '@/stores/asset'
import Tooltip from '@/components/common/VTooltip.vue'
import SvgIcon from './SvgIcon.vue'

const props = defineProps({
  id: { type: [String, Number], required: true },
  name: { type: String, required: true },
  website: { type: String, required: false },
  logo: { type: String, default: null },
  isProcess: { type: Number, default: 0 },
  analysisState: { type: Number, default: 0 },
  serviceType: { type: [String, Number], default: 1 },
  isNewAssetCard: { type: Boolean, default: false },
  languageId: { type: [String, Number], required: true },
  automaticPublishing: { type: Boolean, default: false },
  isFacebook: { type: String, default: '' },
  isCheckPhase: { type: Boolean, default: false },
})

const emit = defineEmits(['new-asset-click'])
const { t } = useI18n()
const router = useRouter()
const assetStore = useAssetStore()

const globalMenuState = inject('globalMenuState', { openMenuId: ref(null) })
const cardId = Symbol('cardId')
provide('cardId', cardId)

const isHovered = ref(false)
const showCustomMenu = computed(() => globalMenuState.openMenuId.value === cardId)
const customMenuPosition = ref({ top: '0px', left: '0px' })
const trimmedName = computed(() => props.name.trim())

const isSetupProcess = computed(() => props.isProcess === 1)
const cardClasses = computed(() => ({
  'cursor-pointer': isClickable.value,
  'cursor-not-allowed opacity-70': !isClickable.value && !isSetupProcess.value,
  'cursor-context-menu': isSetupProcess.value,
}))

const statusText = computed(() => {
  const errorsMessages = [
    'assets.components.card.tooltips.longEntityError',
    'assets.components.card.tooltips.languageError',
    'assets.components.card.tooltips.notFoundError',
  ]

  const texts = [
    'assets.components.card.tooltips.setupInProgress',
    'assets.components.card.tooltips.analyzing',
    'assets.components.card.tooltips.analyzed',
    props.analysisState > 0 ? errorsMessages[props.analysisState - 1] : 'assets.components.card.tooltips.longEntityError',
  ]

  return t(props.isProcess >= 1 && props.isProcess <= 4 ? texts[props.isProcess - 1] : texts[2])
})

const statusTooltipText = computed(() => {
  if (isSetupProcess.value) return t('assets.components.card.tooltips.rightClickToSetup')
  return ''
})

const statusClass = computed(() => {
  const classes = [
    'text-ocean-500',
    'text-blue-100 bg-blue-800',
    'text-lobster-700',
    props.analysisState === 1 ? 'text-yellow-100 bg-yellow-800' : 'text-red-800',
  ]
  return props.isProcess >= 1 && props.isProcess <= 4 ? classes[props.isProcess - 1] : 'bg-gray-100 text-gray-800'
})

const statusIcon = computed(() => {
  const icons = [
    'cog',
    'chart-bar',
    'check-circle',
    props.analysisState === 1 ? 'exclamation-triangle' : 'exclamation-circle',
  ]
  return props.isProcess >= 1 && props.isProcess <= 4 ? icons[props.isProcess - 1] : 'information-circle'
})

const isErrorState = computed(() => props.isProcess === 4)
const analysisState = computed(() => isErrorState.value && props.analysisState === 1)
const isClickable = computed(() =>
    props.isNewAssetCard ||
    (!isErrorState.value && props.isProcess === 3) ||
    analysisState.value,
)

const handleCardClick = (event) => {
  if (isSetupProcess.value) {
    event.preventDefault()
    return
  }

  if (!isClickable.value) {
    event.preventDefault()
    return
  }

  if (props.isNewAssetCard) {
    emit('new-asset-click')
    return
  }

  // if (props.isCheckPhase) {
  //   assetStore.startCheckPhase(props.id)
  //   router.push({ name: 'setupStep', params: { step: 'information' } })
  //   return
  // }

  if (analysisState.value) openCustomMenu(event)
  else prepareAndNavigate()
}

const handleMouseDown = (event) => {
  if (!isClickable.value && !isSetupProcess.value) event.preventDefault()
}

const handleRightClick = (event) => {
  if (isClickable.value || isSetupProcess.value) openCustomMenu(event)
}

const handleStatusClick = (event) => {
  if (analysisState.value || isSetupProcess.value) openCustomMenu(event)
}

const openCustomMenu = (event) => {
  event.preventDefault()
  event.stopPropagation()
  globalMenuState.openMenuId.value = cardId
  customMenuPosition.value = {
    top: `${event.clientY}px`,
    left: `${event.clientX}px`,
  }
}

const closeCustomMenu = (event) => {
  if (showCustomMenu.value && !event.target.closest('.custom-menu')) globalMenuState.openMenuId.value = null
}

const handleMenuItemClick = async (action) => {
  if (action === 'continue' && isSetupProcess.value) {
    await assetStore.resetAssetData()
    await assetStore.startSetupFlow(props.id)
    await router.push({ name: 'createAsset' })
  } else if (action === 'open' && !analysisState.value) {
    await prepareAndNavigate()
  } else if (action === 'edit' && analysisState.value) {
    await assetStore.resetAssetData()
    await assetStore.setAssetServiceType(props.serviceType)
    await router.push({ name: 'editAsset' })
  }
  globalMenuState.openMenuId.value = null
}

const prepareAndNavigate = async () => {
  await assetStore.resetAssetData()
  await assetStore.setAssetId(props.id)
  await assetStore.setAssetLanguage(props.languageId)
  await assetStore.setAssetServiceType(props.serviceType)
  await assetStore.setAssetName(trimmedName.value)
  await assetStore.setAutomaticPublishing(props.automaticPublishing)

  await router.push({ name: 'dashboard' })
}

onMounted(() => {
  document.addEventListener('click', closeCustomMenu)
  window.addEventListener('scroll', closeCustomMenu)
})

onUnmounted(() => {
  document.removeEventListener('click', closeCustomMenu)
  window.removeEventListener('scroll', closeCustomMenu)
})
</script>