<template>
  <div class="component-layout h-[calc(100vh-10rem)]">
    <div class="flex flex-col h-[calc(100vh-14rem)]">
      <!-- Alert -->
      <div class="mb-4 flex-shrink-0">
        <Alert
          v-if="hasErrorAssets && showWarning"
          class="w-full"
          layout="px-4"
          status="warning"
          :message="t('assets.warnings.hasErrors', { count: errorAssetCount })"
          :hasAction="false"
          :onDismiss="dismissWarning"
        />
      </div>

      <!-- Header Section -->
      <div
        v-if="Assets.length > 0 || hasSearched"
        class="w-full flex flex-col sm:flex-row items-center sm:justify-between gap-4 mb-4 flex-shrink-0"
      >
        <div class="w-full flex items-center sm:w-auto">
          <customButton
            type="button"
            :buttonText="t('assets.newAsset')"
            @click="newAssetClick"
            icon="add"
            icon-position="left"
            :show-icon="true"
            size="sm"
            container-class="w-full sm:w-auto"
            :full-width="true"
          />
          <VTooltip :title="t('common.tooltipInformations.assets')" information>
            <template #content>
              <icon-component
                icon="info"
                iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
              />
            </template>
          </VTooltip>
        </div>

        <div class="w-full md:w-auto sm:w-64">
          <custom-input
            :icon="searchQuery || hasSearched ? 'close' : 'search'"
            :show-icon="true"
            :icon-clickable="true"
            v-model="searchQuery"
            @keyup.enter="handleSearch"
            @icon-clicked="clearSearch"
            :placeholder="t('assets.search')"
            :show-error="false"
          />
        </div>
      </div>

      <!-- Content Area -->
      <div class="flex-1 overflow-auto thin-scrollbar">
        <!-- Loading State -->
        <div v-if="load" class="h-full flex justify-center items-center">
          <loading
            :showLoadingText="true"
            :loading="load"
            :loadingText="t('assets.loading')"
            loading-text-class="font-semibold"
          />
        </div>

        <div v-else class="h-full">
          <!-- Empty State -->
          <div
            v-if="Assets.length === 0"
            class="h-full flex flex-col justify-center items-center space-y-6"
          >
            <img
              src="../../../../assets/logo/logoB.svg"
              alt="logo"
              class="w-40 h-40"
            />
            <template v-if="!hasSearched">
              <div class="text-center space-y-2">
                <h3 class="text-lg font-semibold text-gray-900">
                  {{ t("assets.noAssets") }}
                </h3>
                <p class="text-sm text-gray-500">{{ t("assets.addAsset") }}</p>
              </div>
              <customButton
                type="button"
                :buttonText="t('assets.newAssetAdd')"
                @click="newAssetClick"
                icon="add"
                icon-position="left"
                size="md"
                class="mt-4"
              />
            </template>
            <template v-else>
              <p class="text-lg text-gray-600">
                {{ t("assets.noAssetsFound") }}
              </p>
            </template>
          </div>

          <!-- Cards Grid -->
          <div v-if="Assets.length > 0" class="h-full pb-4">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4"
            >
              <card
                v-for="asset in Assets"
                :key="asset.id"
                :id="asset.id"
                :name="asset.name"
                :website="asset.website"
                :isProcess="Number(asset.isProcess)"
                :languageId="asset.languageId"
                :serviceType="asset.serviceType"
                :analysisState="Number(asset.analysisState)"
                :automaticPublishing="asset.automaticPublishing === 1"
                :isFacebook="asset.facebookAccessExpireDate"
                :is-check-phase="Number(asset.isCheckPhase) === 1"
                class="h-full"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div v-if="pageCount > 1 && !hasSearched" class="mt-4 flex-shrink-0">
        <div class="flex justify-end">
          <pagination
            :currentPage="currentPage"
            :page-count="pageCount"
            @page-changed="fetchPage"
          />
        </div>
      </div>
    </div>
  </div>
  <ServiceTypeModal v-model="showServiceTypeModal" />
</template>

<script setup>
// Vue.js ve Bağlam Yönetim Fonksiyonları
import { ref, onMounted, provide } from "vue";
import { useAssetStore } from "@/stores/asset";
import axios from "@/plugins/axiosInstance";
import { assets } from "@/networking/urlManager";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import { useI18n } from "vue-i18n";

// Bileşenler
import Alert from "@/components/feedback/Alert.vue";
import card from "./components/card/index.vue";
import pagination from "@/components/base/pagination/index.vue";
import loading from "@/components/loading/VLoading.vue";
import ServiceTypeModal from "@/views/entity/tabs/entities/components/create/components/serviceTypeModal/index.vue";
import customButton from "../../../../components/base/buttons/VButton.vue";
import CustomInput from "@/components/base/inputs/VInput.vue";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const assetStore = useAssetStore();
const { t } = useI18n();

const Assets = ref([]);
const load = ref(false);
const searchQuery = ref("");
const currentPage = ref(1);
const totalCount = ref(1);
const pageCount = ref(1);
const showServiceTypeModal = ref(false);
const hasSearched = ref(false);
const isActive = ref(1);
const pageSize = ref(15);

// Warning widget için ref'ler
const hasErrorAssets = ref(false);
const errorAssetCount = ref(0);
const showWarning = ref(true);

const globalMenuState = {
  openMenuId: ref(null),
};

provide("globalMenuState", globalMenuState);

const getAll = async () => {
  load.value = true;
  setTimeout(() => {
    axios
      .get(assets.getAll, {
        params: {
          search: searchQuery.value,
          page: currentPage.value - 1,
          isActive: 1,
          pageSize: pageSize.value,
          sort: "id DESC",
        },
      })
      .then((result) => {
        Assets.value = result.data.data.detail;
        totalCount.value = result.data.data.totalCount;
        pageCount.value = result.data.data.pageCount;
        assetStore.setAssets(result.data.data.detail);
        checkErrorAssets(result.data.data.detail);
      })
      .catch((err) => {
        responseHandlerService.handleError(err, t("assets.error.general"));
      })
      .finally(() => {
        load.value = false;
      });
  }, 1000);
};

const checkErrorAssets = (assets) => {
  hasErrorAssets.value = Number(assets[0]?.failCount) > 0;
  errorAssetCount.value = Number(assets[0]?.failCount);
};

const dismissWarning = () => {
  showWarning.value = false;
};

const handleSearch = () => {
  if (searchQuery.value.trim()) {
    hasSearched.value = true;
    currentPage.value = 1;
    getAll();
  }
};

const clearSearch = () => {
  searchQuery.value = "";
  if (hasSearched.value) {
    hasSearched.value = false;
    currentPage.value = 1;
    getAll();
  }
};

const fetchPage = (page) => {
  currentPage.value = page;
  getAll();
};

const newAssetClick = () => {
  assetStore.resetAssetData();
  showServiceTypeModal.value = true;
};

const viewErrorAssets = async () => {
  isActive.value = 0;
  await getAll();
};

onMounted(async () => {
  await assetStore.setAssetId(null);
  isActive.value = 1;
  window.screen.width > 1600 ? (pageSize.value = 15) : (pageSize.value = 12);
  await getAll();
});
</script>
