<template>
  <div
    ref="triggerRef"
    :class="[
      containerClass,
      { 'w-full': fullWidth, 'inline-block': !fullWidth },
    ]"
    v-bind="$attrs"
  >
    <slot name="content"> </slot>
    <teleport :to="information && teleportTo ? teleportTo : 'body'">
      <div
        v-if="shouldShow"
        ref="tooltipRef"
        class="fixed flex-col items-center flex pointer-events-none px-3 py-2 rounded-lg shadow-lg transition-opacity duration-300"
        :class="[
          tipClass,
          colorVariations[color]?.[variation] ||
            colorVariations.default[variation],
          { 'bg-white  border border-gray-300 p-4': information },
        ]"
        :style="tooltipStylesWithZIndex"
      >
        <div v-if="information" class="text-black min-w-52 max-w-96">
          <span class="flex items-center justify-start space-x-2 mb-2">
            <icon-component icon="info" iconClass="text-primary text-xl" />
            <span class="text-sm">{{
              $t("settings.components.setups.information.title")
            }}</span>
          </span>
          <hr />
          <span
            class="text-left text-black text-xs break-words whitespace-pre-wrap"
          >
            {{ title }}
          </span>
        </div>
        <span
          v-else
          class="text-center text-xs break-words whitespace-pre-wrap"
          :style="{ maxWidth: maxWidth }"
        >
          {{ title }}
        </span>
        <div
          v-if="!information"
          ref="arrowRef"
          class="absolute w-3 h-3 rotate-45"
          :class="[arrowColorClass]"
          :style="arrowStyles"
        ></div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import {
  computePosition,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
} from "@floating-ui/dom";
import IconComponent from "@/components/common/VIcon.vue";

const props = defineProps({
  title: String,
  tipClass: String,
  displayCondition: { type: Boolean, default: true },
  externalTrigger: { type: Boolean, default: false },
  isTriggered: { type: Boolean, default: false },
  information: { type: Boolean, default: false },
  teleportTo: { type: String, default: '' },
  color: {
    type: String,
    default: "default",
    validator: (value) =>
      [
        "primary",
        "secondary",
        "accent",
        "neutral",
        "indigo",
        "default",
      ].includes(value),
  },
  variation: {
    type: String,
    default: "solid",
    validator: (value) => ["solid", "outline"].includes(value),
  },
  maxWidth: {
    type: [Number, String],
    default: 200,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: String,
    default: "",
  },
  zIndex: { type: String, default: "99" },
  placement: {
    // Added placement prop
    type: String,
    default: "top", // Default placement
    validator: (value) => ["top", "bottom", "left", "right"].includes(value),
  },
});

const isOpen = ref(false);
const triggerRef = ref(null);
const tooltipRef = ref(null);
const arrowRef = ref(null);

const tooltipStyles = ref({});
const arrowStyles = ref({});

const maxWidth = computed(() =>
  props.maxWidth ? `${props.maxWidth}px` : "200px"
);
let cleanup = null;

const shouldShow = computed(() => props.displayCondition && isOpen.value);

const tooltipStylesWithZIndex = computed(() => ({
  ...tooltipStyles.value,
  zIndex: props.zIndex,
}));

const colorVariations = computed(() => ({
  primary: {
    solid: "bg-primary text-white",
    outline: "bg-white text-primary border border-primary",
  },
  secondary: {
    solid: "bg-secondary text-white",
    outline: "bg-white text-secondary border border-secondary",
  },
  accent: {
    solid: "bg-accent text-white",
    outline: "bg-white text-accent border border-accent",
  },
  neutral: {
    solid: "bg-neutral text-gray-800",
    outline: "bg-white text-gray-800 border border-neutral",
  },
  indigo: {
    solid: "bg-indigo text-white",
    outline: "bg-white text-indigo border border-indigo",
  },
  default: {
    solid: "bg-gray-800 text-white",
    outline: "bg-white text-gray-800 border border-gray-300",
  },
}));

const arrowColorClass = computed(() =>
  props.variation === "outline"
    ? "bg-white"
    : colorVariations.value[props.color]?.solid.split(" ")[0] || "bg-gray-800"
);

const updatePosition = () => {
  if (!triggerRef.value || !tooltipRef.value) return;

  computePosition(triggerRef.value, tooltipRef.value, {
    // Use props.placement here
    placement: props.placement,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 5 }),
      arrow({ element: arrowRef.value }),
    ],
  }).then(({ x, y, placement, middlewareData }) => {
    Object.assign(tooltipStyles.value, { left: `${x}px`, top: `${y}px` });

    const staticSide = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[placement.split("-")[0]];

    if (middlewareData.arrow) {
      const { x: arrowX, y: arrowY } = middlewareData.arrow;
      Object.assign(arrowStyles.value, {
        left: arrowX != null ? `${arrowX}px` : "",
        top: arrowY != null ? `${arrowY}px` : "",
        right: "",
        bottom: "",
        [staticSide]: "-4px",
      });
    }
  });
};

const handleOutsideClick = (event) => {
  if (
    props.information &&
    isOpen.value &&
    !tooltipRef.value.contains(event.target) &&
    !triggerRef.value.contains(event.target)
  ) {
    isOpen.value = false;
    if (cleanup) {
      cleanup();
      cleanup = null;
    }
  }
};

const toggleTooltip = () => {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    nextTick(() => updatePosition()); //removed setupAutoUpdate - unnecessary here
    setupAutoUpdate();
  } else if (cleanup) {
    cleanup();
    cleanup = null;
  }
};

const setupAutoUpdate = () => {
  if (triggerRef.value && tooltipRef.value) {
    cleanup = autoUpdate(triggerRef.value, tooltipRef.value, updatePosition);
  }
};

onMounted(() => {
  if (!triggerRef.value) return;
  document.addEventListener("click", handleOutsideClick);

  const handleEvent = (event) => {
    isOpen.value = !isOpen.value;
    if (isOpen.value) {
      nextTick(() => {
        updatePosition();
        setupAutoUpdate();
      });
    } else if (cleanup) {
      cleanup();
      cleanup = null;
    }
  };

  if (props.information) {
    triggerRef.value.addEventListener("click", handleEvent);
  } else {
    triggerRef.value.addEventListener("mouseenter", () => {
      isOpen.value = true;
      nextTick(() => {
        updatePosition();
        setupAutoUpdate();
      });
    });
    triggerRef.value.addEventListener("mouseleave", () => {
      isOpen.value = false;
      if (cleanup) {
        cleanup();
        cleanup = null;
      }
    });
  }
});

onBeforeUnmount(() => {
  if (triggerRef.value) {
    triggerRef.value.removeEventListener("click", toggleTooltip);
    triggerRef.value.removeEventListener(
      "mouseenter",
      () => (isOpen.value = true)
    );
    triggerRef.value.removeEventListener(
      "mouseleave",
      () => (isOpen.value = false)
    );
  }
  document.removeEventListener("click", handleOutsideClick);
  if (cleanup) {
    cleanup();
  }
});

watch(
  () => props.displayCondition,
  (newValue) => {
    if (!newValue) {
      isOpen.value = false;
    }
  }
);
</script>
