<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="handleClose"
    class="flex justify-center items-center" :content-class="[
      'flex flex-col bg-white w-full shadow-lg h-screen lg:h-auto rounded-none md:rounded-md',
      isTrendContent
        ? 'md:w-[calc(100%-4rem)] lg:w-full max-w-[640px]'
        : 'md:w-[calc(100%-4rem)] lg:w-full max-w-5xl',
    ]">
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div
        class="sticky top-0 z-10 w-full flex items-center justify-between bg-primary/95 px-6 py-1 text-white shrink-0 rounded-t-md">
        <h2 class="text-base font-medium flex items-center gap-2">
          <span class="material-symbols-outlined !text-[20px]">{{
            isTrendContent ? "trending_up" : "smart_toy"
          }}</span>
          <span>{{
            isTrendContent
              ? t(
                "entity.components.newContent.components.newContentModal.createTrendContentTitle"
              )
              : t(
                "entity.components.newContent.components.newContentModal.createContentTitle"
              )
          }}</span>
        </h2>
        <button @click="$emit('update:modelValue', false)"
          class="p-1.5 rounded-md hover:bg-white/10 active:bg-white/20 transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Scrollable Content -->
      <div class="flex-1 overflow-y-auto">
        <div class="px-4 md:px-6 py-4 md:py-5 space-y-6">
          <form class="space-y-6">
            <!-- Trend Content Info -->
            <div v-if="isTrendContent">
              <div class="bg-primary/5 p-5 rounded-lg space-y-6">
                <!-- Trend Keyword -->
                <div>
                  <div class="flex items-center gap-2 mb-2">
                    <span class="material-symbols-outlined !text-[18px] text-primary">key</span>
                    <p class="text-sm font-medium text-gray-600">
                      {{
                        t(
                          "entity.components.newContent.components.newContentModal.trendKeyword"
                        )
                      }}
                    </p>
                  </div>
                  <div class="bg-white px-4 py-3 rounded-md border border-gray-100">
                    <p class="text-base text-gray-900">{{ trendKeyword }}</p>
                  </div>
                </div>

                <!-- Trend URL -->
                <!-- <div>
                  <div class="flex items-center gap-2 mb-2">
                    <span class="material-symbols-outlined !text-[18px] text-primary">link</span>
                    <p class="text-sm font-medium text-gray-600">
                      {{
                        t(
                          "entity.components.newContent.components.newContentModal.trendUrl"
                        )
                      }}
                    </p>
                  </div>
                  <div class="bg-white px-4 py-3 rounded-md border border-gray-100">
                    <a :href="trendUrl" target="_blank" rel="noopener noreferrer"
                      class="text-base text-primary hover:text-primary/80 hover:underline">
                      {{ extractDomain(trendUrl) }}
                    </a>
                  </div>
                </div> -->

                <!-- <div>
                  <div class="flex items-center gap-2 mb-2">
                    <span class="material-symbols-outlined !text-[18px] text-primary">translate</span>
                    <p class="text-sm font-medium text-gray-600">
                      {{
                        t(
                          "entity.components.newContent.components.newContentModal.languageSelectionLabel"
                        )
                      }}
                    </p>
                  </div>
                  <LanguageSelector v-model="formData.languageId" :description="t(
                    'entity.components.newContent.components.newContentModal.languageSelectionDescription'
                  )
                    " :error="validationErrors.languageId" showSearch class="[&_.select-input]:!bg-white" />
                </div> -->
              </div>
            </div>

            <!-- Normal Form Content -->
            <div v-if="!isTrendContent" class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
              <!-- Left Side -->
              <div class="space-y-4 lg:space-y-5">
                <LanguageSelector v-model="formData.languageId" :label="t(
                  'entity.components.newContent.components.newContentModal.languageSelectionLabel'
                )
                  " :description="t(
                    'entity.components.newContent.components.newContentModal.languageSelectionDescription'
                  )
                    " :error="validationErrors.languageId" :show-search="false" />

                <CustomInput v-model="formData.assistiveDescription" type="textarea" :label="t(
                  'entity.components.newContent.components.newContentModal.contentNoteLabel'
                )
                  " :description="t(
                    'entity.components.newContent.components.newContentModal.contentNoteDescription'
                  )
                    " containerClass="w-full" additionalInputClass="resize-none min-h-[120px]"
                  :error="validationErrors.assistiveDescription" :show-error="false" />

                <TagInput :label="t(
                  'entity.components.newContent.components.newContentModal.keywordsLabel'
                )
                  " v-model="formData.keywords" :secondary="true" containerClass="w-full" :description="t(
                    'entity.components.newContent.components.newContentModal.keywordsDescription'
                  )
                    " :isStateMode="false" :maxCount="52" :error="validationErrors.keywords" />
              </div>

              <!-- Right Side - Media -->
              <div class="space-y-4 lg:space-y-5">
                <div class="h-full flex flex-col">
                  <label class="text-sm font-medium text-gray-700 flex items-center justify-between mb-1">
                    <span>{{ t("common.imageUpload") }}</span>
                    <span class="text-xs text-gray-500">{{ formData.images.length }}/2
                      {{ t("common.images") }}</span>
                  </label>

                  <div v-if="formData.images.length < 2"
                    class="border-2 border-dashed border-gray-200 rounded-xl p-4 md:p-6 hover:border-primary/40 transition-colors cursor-pointer bg-gray-50/50 flex-grow"
                    :class="{ 'border-red-300': validationErrors.images }" @click="triggerImageUpload" @dragover.prevent
                    @drop.prevent="handleImageDrop">
                    <div class="flex flex-col items-center justify-center h-full space-y-3">
                      <div class="p-3 bg-primary/10 rounded-full">
                        <span class="material-symbols-outlined !text-[28px] text-primary">cloud_upload</span>
                      </div>
                      <div class="text-center">
                        <p class="text-sm text-gray-600">
                          {{ t("common.dragAndDrop") }}
                        </p>
                        <p class="text-xs text-gray-500 mt-1">
                          PNG, JPG up to 10MB
                        </p>
                      </div>
                      <button type="button" class="text-sm text-primary hover:text-primary/80 font-medium">
                        {{ t("common.browse") }}
                      </button>
                    </div>
                    <input type="file" ref="fileInput" class="hidden" accept="image/jpeg,image/png"
                      @change="handleImageSelect" multiple />
                  </div>

                  <!-- Image Preview Grid -->
                  <div v-if="formData.images.length > 0" class="flex-grow grid grid-cols-2 gap-3">
                    <div v-for="(image, index) in formData.images" :key="index"
                      class="relative rounded-lg overflow-hidden group aspect-video">
                      <img :src="image.preview" class="w-full h-full object-cover" alt="Preview" />
                      <div
                        class="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <button type="button" @click="removeImage(index)"
                          class="p-2 bg-white/90 rounded-full shadow-lg hover:bg-white transform transition-transform hover:scale-105 flex items-center">
                          <span class="material-symbols-outlined !text-[18px] text-gray-600">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Image Description -->
                  <CustomInput v-if="formData.images.length === 0" v-model="formData.assistiveImageDescription"
                    type="textarea" :label="t(
                      'entity.components.newContent.components.newContentModal.imageNoteLabel'
                    )
                      " :description="t(
                        'entity.components.newContent.components.newContentModal.imageNoteDescription'
                      )
                        " containerClass="w-full mt-4" additionalInputClass="resize-none flex-grow"
                    :error="validationErrors.assistiveImageDescription" :show-error="false" />
                </div>
              </div>
            </div>

            <!-- Content Length Selector  -->
            <div v-if="!isTrendContent">
              <VSelect v-model="formData.contentLength" :options="contentLengthOptions" :label="t(
                'entity.components.newContent.components.newContentModal.contentLengthLabel'
              )
                " :description="t(
                  'entity.components.newContent.components.newContentModal.contentLengthDescription'
                )
                  " :error="validationErrors.contentLength" :placeholder="t(
                    'entity.components.newContent.components.newContentModal.selectContentLength'
                  )
                    " size="sm" />
            </div>
          </form>
        </div>
      </div>

      <!-- Footer -->
      <div class="sticky bottom-0 z-10 px-4 md:px-6 py-4 bg-gray-50 border-t border-gray-100 shrink-0 rounded-b-md">
        <CustomButton type="button" @click="handleSubmit" :button-text="t(
          'entity.components.newContent.components.newContentModal.createButton'
        )
          " icon="auto_awesome" :show-icon="true" size="md" :full-width="true" />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { useI18n } from "vue-i18n";
import { useAssetStore } from "@/stores/asset";
import CustomInput from "@/components/base/inputs/VInput.vue";
import CustomButton from "@/components/base/buttons/VButton.vue";
import TagInput from "@/components/base/inputs/VTagInput.vue";
import LanguageSelector from "@/components/base/inputs/VLanguageSelector.vue";
import VSelect from "@/components/base/inputs/VSelect.vue";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import { createNewContentSchema } from "@/validations/schemas/newContentModal.schema";
import { formatFileSize } from "@/utils/common";

// Content Length enum
const CONTENT_LENGTH = {
  short: 1,
  medium: 2,
  long: 3,
};

const props = defineProps({
  isTrendContent: {
    type: Boolean,
    default: false,
  },
  trendKeyword: {
    type: String,
    default: "",
  },
  trendUrl: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "formSubmitted"]);
const { t } = useI18n();
const assetStore = useAssetStore();
const fileInput = ref(null);
const validationErrors = reactive({});

// Content length options
const contentLengthOptions = computed(() => [
  {
    value: CONTENT_LENGTH.short,
    label: t(
      "entity.components.newContent.components.newContentModal.contentLength.short"
    ),
  },
  {
    value: CONTENT_LENGTH.medium,
    label: t(
      "entity.components.newContent.components.newContentModal.contentLength.medium"
    ),
  },
  {
    value: CONTENT_LENGTH.long,
    label: t(
      "entity.components.newContent.components.newContentModal.contentLength.long"
    ),
  },
]);

// Form data
const formData = reactive({
  languageId: assetStore.assetLanguage || 1,
  contentLength: 2,
  assistiveDescription: "",
  assistiveImageDescription: "",
  keywords: [],
  images: [],
});

// Methods
const handleSubmit = async () => {
  try {
    // Clear previous errors
    Object.keys(validationErrors).forEach(
      (key) => delete validationErrors[key]
    );

    // Validation checks
    if (!props.isTrendContent) {
      if (!formData.keywords || formData.keywords.length < 3) {
        validationErrors.keywords = t("form.errors.minKeywords", { min: 3 });
        responseHandlerService.handleError(t("common.validationError"));
        return;
      }

      if (!formData.contentLength) {
        validationErrors.contentLength = t("form.errors.required");
        responseHandlerService.handleError(t("common.validationError"));
        return;
      }
    }

    // Create FormData object
    const formDataObj = new FormData();

    // Add basic form fields
    formDataObj.append("languageId", formData.languageId);
    formDataObj.append("assetId", assetStore.assetId);
    formDataObj.append("contentLength", formData.contentLength);
    formDataObj.append(
      "assistiveDescription",
      formData.assistiveDescription || ""
    );
    formDataObj.append(
      "assistiveImageDescription",
      formData.assistiveImageDescription || ""
    );
    formDataObj.append("keywords", JSON.stringify(formData.keywords));

    // Add images if exists
    if (formData.images[0])
      formDataObj.append("image1", formData.images[0].file);
    if (formData.images[1])
      formDataObj.append("image2", formData.images[1].file);

    // Add trend data if isTrendContent
    if (props.isTrendContent) {
      formDataObj.append("keyword", props.trendKeyword);
      // formDataObj.append('url', props.trendUrl)
    }

    // Emit FormData object
    emit("formSubmitted", formDataObj);
  } catch (error) {
    console.error("error", error);
    responseHandlerService.handleError(t("common.validationError"));
  }
};

const handleClose = () => {
  // Clear form data
  formData.languageId = assetStore.assetLanguage || 1;
  formData.assistiveDescription = "";
  formData.assistiveImageDescription = "";
  formData.keywords = [];
  formData.images = [];

  // Clear validation errors
  Object.keys(validationErrors).forEach((key) => delete validationErrors[key]);

  emit("update:modelValue", false);
};

// Rest of the methods remain the same
const extractDomain = (url) => {
  try {
    return new URL(url).hostname.replace("www.", "");
  } catch {
    return url;
  }
};

const triggerImageUpload = () => {
  fileInput.value.click();
};

const handleImageSelect = (event) => {
  const files = Array.from(event.target.files);
  files.forEach((file) => handleImageFile(file));
};

const handleImageDrop = (event) => {
  const files = Array.from(event.dataTransfer.files);
  files.forEach((file) => {
    if (file.type.startsWith("image/")) handleImageFile(file);
  });
};

const handleImageFile = (file) => {
  if (formData.images.length >= 2) {
    responseHandlerService.handleWarning(t("common.maxImagesError"));
    return;
  }

  const MAX_TOTAL_SIZE = 10 * 1024 * 1024;

  const currentTotalSize = formData.images.reduce(
    (sum, img) => sum + img.file.size,
    0
  );
  const newTotalSize = currentTotalSize + file.size;

  if (newTotalSize <= MAX_TOTAL_SIZE) {
    const reader = new FileReader();
    reader.onload = (e) => {
      formData.images.push({
        file: file,
        preview: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  } else {
    const { size, unit } = formatFileSize(MAX_TOTAL_SIZE);
    responseHandlerService.handleWarning(
      t("common.fileSizeError", { size, unit })
    );
  }
};

const removeImage = (index) => {
  formData.images.splice(index, 1);
};
</script>
