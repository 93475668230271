<template>
  <div class="bg-white p-4 rounded-lg shadow-sm w-full">
    <h2 class="text-2xl font-bold text-secondary mb-5">
      {{ t('assets.components.create.components.source.websiteConfiguration.title') }} </h2>
    <div v-if="Object.keys(errors).length > 0" class="mb-6">
      <div v-for="(error, key) in errors" :key="key" class="text-red-500 text-sm mb-2">
        {{ error }}
      </div>
    </div>
    <sourceTable v-model="sources"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import sourceTable from './components/sourceTable.vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const emit = defineEmits(['stepValidation'])
const assetStore = useAssetStore()
const { t } = useI18n()
const errors = ref({})

const sources = computed(() => assetStore.assetData.sources)
const introductionList = computed(() => assetStore.assetData.introductionList)
const authList = computed(() => assetStore.assetData.authList)
const isDataReceived = computed(() => assetStore.assetData.isDataReceived)

const validateStep = () => {
  const errors = {}

  // Eğer yetki yoksa direkt dön
  if (!authList.value.isAuth) {
    errors.auth = t('assets.components.create.components.source.websiteConfiguration.errors.authorizationRequired')
    return { isValid: false, errors }
  }

  // Doğrulamaları gerçekleştir
  Object.assign(errors, validateSources(sources.value))
  Object.assign(errors, validateIntroductionFields(introductionList.value))
  Object.assign(errors, validateAuthFields(authList.value))

  // Tüm hata yoksa başarılı
  const isValid = Object.keys(errors).length === 0
  return { isValid, errors }
}


const validate = async () => {
  const validation = validateStep()

  if (!validation.isValid) {
    errors.value = validation.errors
    return validation
  }

  if (isDataReceived.value) {
    return validation
  }

  try {
    await assetStore.setAssetLoading(true)
    const websiteUrl = new URL(introductionList.value.website)

    const response = await axios.post(assets.info, {
      name: introductionList.value.name,
      website: websiteUrl.href,
      languageId: introductionList.value.languageId,
      countryId: introductionList.value.countryId,
      timezoneId: introductionList.value.timezoneId,
      username: authList.value.username,
      password: authList.value.password,
      authUrl: authList.value.authUrl,
      sourceList: sources.value,
      searchEngineLocations: introductionList.value.searchEngineLocations,
    })

    const responseData = response.data.data
    await assetStore.setAssetInputLoading(true)
    await assetStore.setAssetId(responseData.assetId)
    await assetStore.setAssetCustomerLanguageId(responseData.customerLanguageId)
    await assetStore.setInformationList(responseData)
    await assetStore.setIsDataReceived(true)

    validation.isValid = true
    return validation
  } catch (error) {
    validation.isValid = false
    validation.errors.request = t('assets.create.source.websiteConfiguration.errors.generalError')
    responseHandlerService.handleError(error)
    return validation
  } finally {
    await assetStore.setAssetLoading(false)
  }
}

const validateAndEmit = async () => {
  const validation = validateStep()
  errors.value = validation.errors
  emit('stepValidation', validation)
}

watch(() => [...sources.value], validateAndEmit, { deep: true })
watch([introductionList, authList], validateAndEmit, { deep: true })

onMounted(async () => {
  await assetStore.setAssetLoading(false)
  await validateAndEmit()
})

// Utils

const validateAuthFields = (authList) => {
  const errors = {}
  const requiredFields = ['username', 'password', 'authUrl']
  requiredFields.forEach((field) => {
    if (!authList[field]) errors[field] = t(`assets.components.create.components.source.websiteConfiguration.errors.${field}Required`)
  })
  return errors
}

const validateIntroductionFields = (introductionList) => {
  const errors = {}
  if (!introductionList.name) errors.name = t('assets.components.create.components.source.websiteConfiguration.errors.nameRequired')
  if (!introductionList.website) errors.website = t('assets.components.create.components.source.websiteConfiguration.errors.websiteRequired')
  return errors
}

const validateSources = (sources) => {
  const isValid = sources.every((source) => {
    if (!source.sourceCategoryId) return false
    return source.getUrl || source.addUrl || source.editUrl
  })
  return isValid
      ? {}
      : { sources: t('assets.components.create.components.source.websiteConfiguration.errors.sourceValidation') }
}


defineExpose({
  validate,
})
</script>
