import * as yup from 'yup'

export const MAX_KEYWORDS = 1000

const keywordSchema = (t) =>
    yup.object().shape({
      id: yup.number().nullable(),
      keywordId: yup.number().nullable(),
      name: yup
          .string()
          .required(
              t('settings.components.configuration.validation.keyword.nameRequired'),
          )
          .min(
              2,
              t('settings.components.configuration.validation.keyword.minLength'),
          )
          .max(
              100,
              t('settings.components.configuration.validation.keyword.maxLength'),
          ),
      priority: yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .min(
              0,
              t('settings.components.configuration.validation.keyword.priorityRange'),
          )
          .max(
              10,
              t('settings.components.configuration.validation.keyword.priorityRange'),
          )
          .nullable(),
      state: yup
          .number()
          .oneOf(
              [1, 2, 3, 4],
              t('settings.components.configuration.validation.keyword.invalidState'),
          )
          .required(
              t('settings.components.configuration.validation.keyword.stateRequired'),
          ),
    })

export const createConfigurationSchema = (t) => {
  return yup.object().shape({
    countryId: yup.number().required(t('settings.components.configuration.validation.keyword.countryIdRequired')),
    timezoneId: yup.number().required(t('settings.components.configuration.validation.keyword.timezoneIdRequired')),
    keywords: yup
        .array()
        .of(keywordSchema(t))
        .test(
            'keywords-not-empty',
            t('settings.components.configuration.validation.keyword.stateRequired'),
            (value) => {
              return value && value.length > 0
            },
        )
        .test(
            'max-keywords',
            t(
                'settings.components.configuration.validation.keyword.maxKeywordsExceeded',
                { max: MAX_KEYWORDS },
            ),
            function (value) {
              const activeKeywords = value?.filter((k) => k.state !== 3) || []
              return activeKeywords.length <= MAX_KEYWORDS
            },
        )
        .test(
            'duplicate-keywords',
            t(
                'settings.components.configuration.validation.keyword.duplicateKeywords',
            ), // Bu mesajı değiştireceğiz
            function (value) {
              if (!value) return true
              const nameMap = new Map()
              const duplicates = []

              for (const keyword of value) {
                if (keyword.state === 3) continue
                const lowerName = keyword.name.toLowerCase()
                if (nameMap.has(lowerName)) {
                  const existing = nameMap.get(lowerName)
                  if (existing.priority === keyword.priority) {
                    duplicates.push({ first: existing.name, second: keyword.name })
                    return this.createError({
                      message: t(
                          'settings.components.configuration.errors.duplicateDetail',
                          {
                            first: existing.name,
                            second: keyword.name,
                          },
                      ),
                    })
                  }
                }
                nameMap.set(lowerName, {
                  name: keyword.name,
                  priority: keyword.priority,
                })
              }
              return true
            },
        ),
  })
}

// Export helper functions
export const hasChanges = (oldData, newData) => {
  if (!oldData || !newData) return false

  try {
    // Keywords değişiklik kontrolü
    const oldKeywords = sortAndNormalize(oldData.keywords || [])
    const newKeywords = sortAndNormalize(newData.keywords || [])

    const keywordsChanged =
        oldKeywords.length !== newKeywords.length ||
        oldKeywords.some((oldKeyword, index) => {
          const newKeyword = newKeywords[index]
          return (
              (oldKeyword.id || oldKeyword.keywordId) !==
              (newKeyword.id || newKeyword.keywordId) ||
              oldKeyword.name !== newKeyword.name ||
              oldKeyword.priority !== newKeyword.priority ||
              oldKeyword.state !== newKeyword.state
          )
        })

    if (keywordsChanged) return true

    // Locations değişiklik kontrolü - schema validation olmadan
    const oldLocations = normalizeLocations(oldData.locations)
    const newLocations = normalizeLocations(newData.locations)

    const oldCountryId = oldData.countryId
    const newCountryId = newData.countryId

    if (oldCountryId !== newCountryId) return true

    const oldTimezoneId = oldData.timezoneId
    const newTimezoneId = newData.timezoneId

    if (oldTimezoneId !== newTimezoneId) return true

    return JSON.stringify(oldLocations) !== JSON.stringify(newLocations)
  } catch (error) {
    console.error('Error checking changes:', error)
    return true
  }
}

const sortAndNormalize = (keywords) => {
  if (!keywords) return []

  return [...keywords]
      .sort((a, b) => {
        if (a.state === 2 && b.state !== 2) return 1
        if (b.state === 2 && a.state !== 2) return -1
        if (a.state === 2 && b.state === 2) {
          return (a.name || '').localeCompare(b.name || '')
        }
        const aId = a.id || a.keywordId || 0
        const bId = b.id || b.keywordId || 0
        return aId - bId
      })
      .map((k) => ({
        id: k.id || k.keywordId,
        keywordId: k.keywordId,
        name: (k.name || '').toLowerCase().trim(),
        priority: k.priority || null,
        state: k.state,
      }))
}

const normalizeLocations = (locations) => {
  if (!locations) return {}

  const normalized = {}
  Object.keys(locations).forEach((engine) => {
    normalized[engine] = (locations[engine] || [])
        .sort((a, b) => a.locationId - b.locationId)
        .map((loc) => ({
          ...loc,
          state: loc.state,
        }))
  })

  return normalized
}

export const validateConfigurationData = async (data, schema) => {
  try {
    if (!data || !data.keywords.map(k => k.state !== 3) || data.countryId === undefined || data.timezoneId === undefined) {
      return {
        isValid: false,
        errors: { keywords: 'Keywords data is required, countryId and timezoneId also required' },
      }
    }

    await schema.validate(data, { abortEarly: false })
    return { isValid: true, errors: null }
  } catch (error) {
    const formattedErrors = {}

    if (error.inner && Array.isArray(error.inner)) {
      error.inner.forEach((err) => {
        if (err && err.path) {
          const field = err.path.split('.').pop()
          if (!formattedErrors[field]) {
            formattedErrors[field] = err.message
          }
        }
      })
    }

    return {
      isValid: false,
      errors: formattedErrors,
    }
  }
}

// Export state helpers
export const isNewKeyword = (keyword) => keyword.state === 2
export const isActiveKeyword = (keyword) => keyword.state === 1
export const isDeletedKeyword = (keyword) => keyword.state === 3
export const isUpdatedKeyword = (keyword) => keyword.state === 4

// Export Excel helpers
export const validateExcelData = (data) => {
  if (!Array.isArray(data))
    return { errors: ['Invalid data format'], validData: [] }

  const errors = []
  const validData = []

  data.forEach((row, index) => {
    if (!row.Keyword || typeof row.Keyword !== 'string') {
      errors.push({ row: index + 2, message: 'Invalid keyword value' })
      return
    }

    const priority = row.Priority !== undefined ? parseInt(row.Priority) : null
    if (
        row.Priority !== undefined &&
        (isNaN(priority) || priority < 0 || priority > 10)
    ) {
      errors.push({ row: index + 2, message: 'Invalid priority value (0-10)' })
      return
    }

    validData.push({
      name: row.Keyword.trim(),
      priority,
    })
  })

  return { errors, validData }
}