<template>
  <div class="h-[80vh] w-full relative" :class="!loading ? 'component-layout' : ''">
    <div v-if="loading" class="loading-overlay h-[80vh]">
      <loading/>
    </div>
    <sourceTable v-else :use-store="false" v-model="List" :asset-service-type-prop="Number(useAssetStore.assetServiceType)"/>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import sourceTable from './components/sourceTable.vue'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import Loading from '@/components/loading/VLoading.vue'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const emit = defineEmits(['saving'])

const List = ref([])
const loading = ref(false)

const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.sourceConfiguration, {
      params: { assetId: assetStore.assetId },
    })
    List.value = response.data.data
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.errorFetchingData'))
    console.error(error)
  } finally {
    setTimeout(() => (loading.value = false), 500)
  }
}

const save = async () => {
  emit('saving', true)
  try {
    // Prepare data for backend by removing temp IDs
    const sourcesToSave = List.value.map(source => {
      const { id, ...sourceData } = source
      if (id && !String(id).startsWith('temp_')) {
        return { id, ...sourceData }
      }
      return sourceData
    })

    await axios.put(settings.sourceConfiguration, {
      source: sourcesToSave,
      assetId: assetStore.assetId,
    })
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
    // Refresh data after successful save
    await fetch()
  } catch (error) {
    responseHandlerService.handleError(
        error,
        t('settings.errorUpdatingSettings'),
    )
  } finally {
    emit('saving', false)
  }
}

// Watch for changes in List
watch(List, (newValue) => {
  console.log('List updated:', newValue)
}, { deep: true })

onMounted(fetch)

defineExpose({ save })
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}
</style>
