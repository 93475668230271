<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="bg-white p-4 space-y-4">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{
          t(
            "assets.components.create.components.introduction.information.titles.assetInformation"
          )
        }}
      </h2>
      <div
        class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center w-full"
      >
        <customInput
          v-model="list.name"
          :label="
            t(
              'assets.components.create.components.introduction.information.labels.assetName'
            )
          "
          :description="
            t(
              'assets.components.create.components.introduction.information.descriptions.enterAssetName'
            )
          "
          :required="true"
          :loading="inputLoading"
          :error="errors.name"
          container-class="w-full"
          @update:model-value="(value) => handleChange('name', value)"
          @blur="() => handleFieldBlur('name')"
        />
        <customInput
          :information="t('common.tooltipInformations.website')"
          v-model="list.website"
          :label="
            t(
              'assets.components.create.components.introduction.information.labels.website'
            )
          "
          :description="
            t(
              'assets.components.create.components.introduction.information.descriptions.enterWebsite'
            )
          "
          :required="true"
          :loading="inputLoading"
          :error="errors.website"
          container-class="w-full"
          @update:model-value="(value) => handleChange('website', value)"
          @blur="() => handleFieldBlur('website')"
        />
      </div>

      <language-selector
        v-model="list.languageId"
        :label="
          t(
            'assets.components.create.components.introduction.information.labels.languageSelection'
          )
        "
        :description="
          t(
            'assets.components.create.components.introduction.information.descriptions.selectLanguage'
          )
        "
        :error="errors.languageId"
        @update:model-value="(value) => handleChange('languageId', value)"
        @blur="() => handleFieldBlur('languageId')"
      />
      <country-selector
        v-model="list.countryId"
        :label="
          t(
            'assets.components.create.components.introduction.information.labels.countrySelection'
          )
        "
        :description="
          t(
            'assets.components.create.components.introduction.information.descriptions.selectCountry'
          )
        "
        :error="errors.countryId"
        @update:model-value="(value) => handleChange('countryId', value)"
        @timezoneSelected="handleTimezoneSelection"
        @blur="() => handleFieldBlur('countryId')"
      />

      <!-- Yeni eklenen timezone seçici -->
      <timezone-selector
        v-model="list.timezoneId"
        :country-id="list.countryId"
        :label="
          t(
            'assets.components.create.components.introduction.information.labels.timezoneSelection'
          )
        "
        :description="
          t(
            'assets.components.create.components.introduction.information.descriptions.selectTimezone'
          )
        "
        :error="errors.timezoneId"
        @update:model-value="(value) => handleChange('timezoneId', value)"
        @blur="() => handleFieldBlur('timezoneId')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { useAssetStore } from "@/stores/asset";
import customInput from "@/components/base/inputs/VInput.vue";
import LanguageSelector from "@/components/base/inputs/VLanguageSelector.vue";
import CountrySelector from "@/components/base/inputs/VCountrySelector.vue";
import TimezoneSelector from "@/components/base/inputs/VTimezoneSelector.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { createIntroductionSchema } from "@/validations/schemas/asset.create.introduction.schema";

const assetStore = useAssetStore();
const route = useRoute();
const { t } = useI18n();

const emit = defineEmits(["stepValidation", "stepData"]);
const errors = ref({});
const isValid = ref(false);
const touchedFields = ref(new Set());

const inputLoading = computed(() => assetStore.assetData.inputLoading);
const list = computed(() => assetStore.assetData.introductionList);
const assets = computed(() => assetStore.assets);

const validationSchema = computed(() =>
  createIntroductionSchema(t, assets.value, !!route.params.id, route.params.id)
);

const validateField = async (fieldName) => {
  if (!touchedFields.value.has(fieldName)) return;

  try {
    await validationSchema.value.validateAt(fieldName, list.value);
    errors.value[fieldName] = "";
  } catch (error) {
    errors.value[fieldName] = error.message;
  }
};

const validateForm = async () => {
  try {
    await validationSchema.value.validate(list.value, { abortEarly: false });
    isValid.value = true;
    errors.value = {};
    emit("stepValidation", { isValid: true, errors: {} });
    return true;
  } catch (error) {
    isValid.value = false;
    if (error.inner) {
      error.inner.forEach((err) => {
        if (touchedFields.value.has(err.path)) {
          errors.value[err.path] = err.message;
        }
      });
    }
    emit("stepValidation", { isValid: false, errors: errors.value });
    return false;
  }
};

const handleFieldBlur = (fieldName) => {
  touchedFields.value.add(fieldName);
  validateField(fieldName);
};

const handleChange = (fieldName, value) => {
  touchedFields.value.add(fieldName);
  emit("stepData", list.value);
  validateField(fieldName);
};

const handleTimezoneSelection = (timezoneId) => {
  list.value.timezoneId = timezoneId;
  handleChange("timezoneId", timezoneId);
};

watch(() => list.value, validateForm, { deep: true });

watch(
  () => route.params.id,
  () => {
    touchedFields.value.clear();
    errors.value = {};
  }
);

onMounted(validateForm);

defineExpose({
  validate: validateForm,
});
</script>
