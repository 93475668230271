<template>
  <div class="relative">
    <CustomSelect v-model="selectedValue" :options="categories" :loading="loading" :placeholder="computedPlaceholder" :error="error" :clearable="clearable" :noOptionsMessage="t('common.noCategories')" size="sm" class="w-full" @update:modelValue="handleChange" @clear="handleClear"/>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomSelect from '@/components/base/inputs/VSelect.vue'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue', 'error'])

// State
const categories = ref([])
const loading = ref(false)
const error = ref('')
const selectedValue = ref(props.modelValue)

// Computed
const computedPlaceholder = computed(() => {
  if (!loading.value && categories.value.length === 0) return t('common.addCustomCategory')
  return props.placeholder || t('common.selectCategory')
})

// Methods
const fetchCategories = async () => {
  loading.value = true
  error.value = ''

  try {
    const { data } = await axios.get(settings.getKeywordCategories, {
      params: {
        assetId: assetStore.assetId,
      },
    })

    categories.value = data.data.map((category) => ({
      value: category.id,
      label: category.name,
      ...category,
    }))
  } catch (err) {
    const errorMessage = t('errors.fetchCategories')
    error.value = errorMessage
    emit('error', errorMessage)
    console.error('Error fetching categories:', err)
  } finally {
    loading.value = false
  }
}

const handleChange = (value) => {
  selectedValue.value = value
  emit('update:modelValue', value)
}

const handleClear = () => {
  selectedValue.value = null
  emit('update:modelValue', null)
}

// Watch modelValue changes
watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== selectedValue.value) selectedValue.value = newValue
    },
)

// Lifecycle
onMounted(() => {
  fetchCategories()
})

// Expose fetch method for manual refresh
defineExpose({
  fetchCategories,
})
</script>