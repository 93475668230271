<template>
  <div class="flex flex-col" :class="props.containerClass">
    <!-- Label ve Description -->
    <div v-if="label || description" class="mb-2">
      <div class="flex items-center space-x-2 mb-1">
        <div
          :class="[
            'flex items-center space-x-1',
            labelIconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
          ]"
        >
          <icon-component
            v-if="labelIconPosition === 'left' && props.labelIcon"
            :icon="props.labelIcon"
            :class="[props.labelIconClass, 'mr-1', iconClickableClass]"
            @click="emitIconClick"
          />
          <label
            v-if="props.label"
            :for="inputId"
            :class="[props.labelClass, props.additionalLabelClass]"
            class="text-sm"
          >
            {{ props.label
            }}<span v-if="props.required" class="text-primary">*</span>
          </label>
          <VTooltip v-if="information" :title="information" information>
            <template #content>
              <icon-component
                icon="info"
                iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
              />
            </template>
          </VTooltip>
          <icon-component
            v-if="labelIconPosition === 'right' && props.labelIcon"
            :icon="props.labelIcon"
            :class="[props.labelIconClass, 'ml-1', iconClickableClass]"
            @click="emitIconClick"
          />
        </div>
        <img
          v-if="props.showAiIcon"
          :src="aiStarIcon"
          class="cursor-pointer w-4 hover:opacity-70 transition-opacity"
          @click="emitIconClick"
          alt="star icon"
        />
      </div>
      <p
        v-if="props.description"
        :class="[props.descriptionClass, props.additionalDescriptionClass]"
        class="text-xs mb-1"
      >
        {{ props.description }}
      </p>
    </div>

    <!-- Textarea -->
    <div v-if="props.type === 'textarea'" class="w-full relative">
      <input-skeleton
        v-if="props.loading"
        :class="props.autoGrow ? 'h-56' : textAreaHeight"
      />
      <div v-else class="relative">
        <textarea
          ref="textarea"
          :id="inputId"
          :name="props.name"
          :class="[
            baseInputClass,
            sizeClasses,
            props.autoGrow ? 'h-auto max-h-96 min-h-56' : textAreaHeight,
            props.showIcon && props.icon ? iconInputClass : '',
            isTouched && props.error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : '',
            {
              'blur-[10px] hover:blur-0 focus:blur-0 transition-all duration-300':
                props.enableBlur && modelValue,
            },
          ]"
          :placeholder="props.placeholder"
          :value="modelValue"
          :autocomplete="props.autocomplete"
          @input="updateValue"
          @blur="handleBlur"
          @focus="handleFocus"
          @click="handleClick"
          :disabled="props.disabled"
          :required="props.required"
          :readonly="props.readonly"
          :tabindex="props.tabindex"
          :maxlength="maxLength"
        >
        </textarea>
      </div>
    </div>

    <!-- Normal Input -->
    <div
      v-if="
        props.type !== 'select' &&
        props.type !== 'password' &&
        props.type !== 'textarea'
      "
      class="relative"
    >
      <input-skeleton v-if="props.loading" :class="skeletonClass" />
      <div v-else class="relative flex items-center">
        <div
          v-if="props.showIcon && props.icon"
          :class="[
            'absolute inset-y-0 left-0 flex items-center border-r border-gray-300',
            iconPaddingClass,
            iconClickableClass,
          ]"
        >
          <icon-component
            :icon="props.icon"
            :icon-class="iconClass"
            @click="emitIconClick"
          />
        </div>
        <input
          :id="inputId"
          :name="props.name"
          :class="[
            baseInputClass,
            sizeClasses,
            props.showIcon && props.icon ? iconInputClass : '',
            props.showDetailButton ? 'pr-10' : '',
            isTouched && props.error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : '',
          ]"
          :type="props.type"
          :placeholder="props.placeholder"
          :value="modelValue"
          :autocomplete="props.autocomplete"
          @input="updateValue"
          @blur="handleBlur"
          @focus="handleFocus"
          @click="handleClick"
          :disabled="props.disabled"
          :required="props.required"
          :min="props.min"
          :max="props.max"
          :step="props.step"
          :readonly="props.readonly"
        />
        <div
          v-if="props.showDetailButton"
          :class="[
            'absolute inset-y-0 select-none h-7.5 bg-primary rounded-r-md right-0 flex items-center border-l border-gray-300',
            iconPaddingClass,
            'cursor-pointer hover:bg-opacity-90 transition-colors',
          ]"
          @click="emitDetailClick"
        >
          <icon-component
            :icon="props.detailIcon || 'more_vert'"
            :class="props.detailIconClass || 'text-gray-500 !text-base'"
          />
        </div>
      </div>
    </div>

    <!-- Password Input -->
    <div v-else-if="props.type === 'password'" class="relative">
      <div class="relative flex items-center">
        <div
          v-if="props.showIcon && props.icon"
          :class="[
            'absolute inset-y-0 left-0 flex items-center border-r border-gray-300',
            iconPaddingClass,
            iconClickableClass,
          ]"
        >
          <icon-component
            :icon="props.icon"
            :icon-class="iconClass"
            @click="emitIconClick"
          />
        </div>
        <input
          :id="inputId"
          :name="props.name"
          :class="[
            baseInputClass,
            sizeClasses,
            'pr-10',
            props.showIcon && props.icon ? iconInputClass : '',
            isTouched && props.error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : '',
          ]"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="props.placeholder"
          :value="modelValue"
          :autocomplete="props.autocomplete"
          @input="updateValue"
          @blur="handleBlur"
          @focus="handleFocus"
          @click="handleClick"
          :disabled="props.disabled"
          :required="props.required"
          :readonly="props.readonly"
        />
        <button
          type="button"
          class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-800"
          @click="showPassword = !showPassword"
        >
          <icon-component
            :icon="showPassword ? 'visibility' : 'visibility_off'"
            :icon-class="iconClass"
          />
        </button>
      </div>
    </div>

    <!-- Select Input -->
    <div v-else-if="props.type === 'select'" class="relative">
      <input-skeleton v-if="props.loading" :class="skeletonClass" />
      <div v-else class="relative">
        <div
          v-if="props.showIcon && props.icon"
          :class="[
            'absolute inset-y-0 left-0 flex items-center border-r border-gray-300',
            iconPaddingClass,
            iconClickableClass,
          ]"
        >
          <icon-component
            :icon="props.icon"
            :icon-class="iconClass"
            @click="emitIconClick"
          />
        </div>
        <p
          v-if="!modelValue"
          class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500"
          :class="props.showIcon && props.icon ? 'left-10' : ''"
        >
          {{ props.placeholder }}
        </p>
        <select
          :id="inputId"
          :name="props.name"
          :class="[
            baseInputClass,
            sizeClasses,
            props.showIcon && props.icon ? iconInputClass : '',
            isTouched && props.error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : '',
          ]"
          :disabled="props.disabled"
          :value="modelValue"
          :autocomplete="props.autocomplete"
          @input="updateValue"
          @blur="handleBlur"
          @focus="handleFocus"
          @click="handleClick"
          :required="props.required"
        >
          <option v-if="props.loading" value="">Yükleniyor...</option>
          <option v-else-if="props.selectList.length === 0" value="">
            Veri yok
          </option>
          <option
            v-else
            v-for="item in props.selectList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Error Message -->
    <div v-if="showError" class="h-4">
      <div v-if="props.error" class="flex items-start gap-1">
        <span class="material-symbols-outlined text-red-500 !text-[14px] mt-0.5"
          >error</span
        >
        <p class="text-xs text-red-500 break-words">{{ props.error }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick, onMounted, watch } from "vue";
import InputSkeleton from "@/components/loading/VInputSkeleton.vue";
import IconComponent from "@/components/common/VIcon.vue";
import { v4 as uuidv4 } from "uuid";
import VTooltip from "@/components/common/VTooltip.vue";

const props = defineProps({
  information: { type: String, required: false },
  label: { type: String, default: "" },
  description: { type: String, default: "" },
  showDetailButton: { type: Boolean, default: false },
  detailIcon: { type: String, default: "data_exploration" },
  detailIconClass: { type: String, default: "text-white !text-base" },
  required: { type: Boolean, default: false },
  modelValue: { type: [String, Number, Array, Object], default: "" },
  selectList: { type: Array, default: () => [] },
  type: {
    type: String,
    default: "text",
    validator: (value) =>
      [
        "text",
        "email",
        "password",
        "number",
        "textarea",
        "select",
        "tel",
      ].includes(value),
  },
  additionalInputClass: { type: String, default: "" },
  labelClass: { type: String, default: "font-semibold text-secondary" },
  descriptionClass: { type: String, default: "text-gray-600" },
  additionalDescriptionClass: { type: String, default: "" },
  additionalLabelClass: { type: String, default: "" },
  containerClass: { type: String, default: "" },
  placeholder: { type: String, default: "" },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  autoGrow: { type: Boolean, default: false },
  textAreaHeight: { type: String, default: "h-24" },
  showAiIcon: { type: Boolean, default: false },
  icon: { type: String, default: "link" },
  iconClass: { type: String, default: "text-secondary !text-base" },
  showIcon: { type: Boolean, default: false },
  size: {
    type: String,
    default: "sm",
    validator: (value) => ["sm", "md", "lg"].includes(value),
  },
  labelIcon: { type: String, default: "" },
  labelIconClass: { type: String, default: "text-primary !text-[20px]" },
  labelIconPosition: {
    type: String,
    default: "left",
    validator: (value) => ["left", "right"].includes(value),
  },
  iconClickable: { type: Boolean, default: false },
  aiIconClickable: { type: Boolean, default: true },
  error: { type: String, default: "" },
  showError: { type: Boolean, default: true },
  name: { type: String, default: "" },
  autocomplete: { type: String, default: "off" },
  min: { type: [Number, String], default: undefined },
  max: { type: [Number, String], default: undefined },
  maxLength: { type: [Number, String], default: undefined },
  step: { type: [Number, String], default: undefined },
  enableBlur: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  tabindex: { type: [String, Number], default: "0" },
});

const emit = defineEmits([
  "update:modelValue",
  "iconClicked",
  "focus",
  "blur",
  "detailClicked",
  "click",
]);

const showPassword = ref(false);
const textarea = ref(null);
const aiStarIcon = require("@/assets/icons/aiStar.png");
const inputId = ref(uuidv4());
const isTouched = ref(false);

const baseInputClass = computed(() => [
  "border border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 w-full thin-scrollbar",
  props.additionalInputClass,
]);

const handleClick = (event) => {
  emit("click", event);
};

const emitDetailClick = () => {
  emit("detailClicked");
};

const handleBlur = (event) => {
  isTouched.value = true;
  emit("blur", event);
};

const handleFocus = (event) => {
  emit("focus", event);
};

const sizeClasses = computed(() => {
  switch (props.size) {
    case "sm":
      return "text-xs py-1.5 px-2 placeholder:text-xs";
    case "md":
      return "text-sm py-2 px-3 placeholder:text-sm";
    case "lg":
      return "text-base py-3 px-4 placeholder:text-base";
    default:
      return "text-sm py-2 px-3 placeholder:text-sm";
  }
});

const iconPaddingClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "pl-2 pr-2";
    case "lg":
      return "pl-3 pr-3";
    default:
      return "pl-2.5 pr-2.5";
  }
});

const iconInputClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "pl-10";
    case "lg":
      return "pl-12";
    default:
      return "pl-11";
  }
});

const skeletonClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "h-7 w-full";
    case "lg":
      return "h-12 w-full";
    default:
      return "h-9 w-full";
  }
});

const iconClass = computed(() => {
  return props.icon ? `${props.iconClass} text-base` : "";
});

const iconClickableClass = computed(() => {
  return props.iconClickable
    ? "cursor-pointer hover:text-blue-600 transition-colors"
    : "";
});

const updateValue = (event) => {
  let value = event.target.value;
  if (props.type === "number") {
    value = value === "" ? "" : Number(value);
  }
  emit("update:modelValue", value);
  if (props.autoGrow && props.type === "textarea") {
    nextTick(adjustHeight);
  }
};

const emitFocus = (event) => {
  emit("focus", event);
};

const adjustHeight = () => {
  if (textarea.value && props.autoGrow) {
    textarea.value.style.height = "auto";
    textarea.value.style.height = `${textarea.value.scrollHeight}px`;
  }
};

const emitIconClick = () => {
  if (props.aiIconClickable) {
    emit("iconClicked");
  }
};

// Watch handlers
watch(
  () => props.modelValue,
  (newValue) => {
    if (props.type === "textarea" && props.autoGrow) {
      nextTick(adjustHeight);
    }

    // Mark as touched if there's a value
    if (newValue && !isTouched.value) {
      isTouched.value = true;
    }
  }
);

// Reset touched state when error is cleared
watch(
  () => props.error,
  (newError) => {
    if (!newError) {
      isTouched.value = false;
    }
  }
);

// Lifecycle hooks
onMounted(() => {
  if (props.type === "textarea" && props.autoGrow) {
    adjustHeight();
  }

  // Set initial touched state if there's a value
  if (props.modelValue) {
    isTouched.value = true;
  }
});

// Methods to expose
defineExpose({
  focus: () => {
    if (textarea.value) {
      textarea.value.focus();
    }
  },
  blur: () => {
    if (textarea.value) {
      textarea.value.blur();
    }
  },
  reset: () => {
    isTouched.value = false;
    emit("update:modelValue", "");
  },
  setTouched: (value = true) => {
    isTouched.value = value;
  },
  isTouched: () => isTouched.value,
});
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
