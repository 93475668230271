<template>
  <div class="bg-white rounded-xl shadow-sm p-6">
    <div class="grid grid-cols-1 gap-6">
      <div class="flex flex-col items-center">
        <!-- <span class="flex items-center" > -->
          <h3
            class="text-sm font-medium text-slate-600 flex items-center gap-2 self-start"
          >
            <span class="material-symbols-outlined text-primary"
              >analytics</span
            >
            {{ t("entity.components.contentReview.contentScore") }}
            <span>
          <VTooltip
            :title="t('common.tooltipInformations.contentScore')"
            information
          >
            <template #content>
              <icon-component
                icon="info"
                iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
              />
            </template>
          </VTooltip>
        </span>
          </h3>
         
        <!-- </span> -->
        <div class="w-full h-[200px] flex items-center justify-center">
          <ScoreChart :score="score" />
        </div>
      </div>
      <ScoreAnalysis
        :parsed-message="parsedScoreMessage"
        :carousel-items="carouselItems"
      />
    </div>
  </div>
</template>

<script setup>
import ScoreAnalysis from "./ScoreAnalysis.vue";
import ScoreChart from "@/components/features/charts/ScoreChart.vue";
import { useI18n } from "vue-i18n";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const { t } = useI18n();

defineProps({
  score: Number,
  parsedScoreMessage: Object,
  carouselItems: Array,
});
</script>
