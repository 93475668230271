import { createRouter, createWebHistory } from "vue-router";
import axios from "@/plugins/axiosInstance";
import { auth } from "@/networking/urlManager";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import { useAuthStore } from "@/stores/auth";
import { useAssetStore } from "@/stores/asset";
import { isValidStep } from "@/views/entity/tabs/entities/components/setup/config";

// View imports
import dashboard from "@/views/dashboard/index.vue";
import entity from "@/views/entity/index.vue";
import createAsset from "@/views/entity/tabs/entities/components/create/index.vue";
import entitySetup from "@/views/entity/tabs/entities/components/setup/index.vue";
import entitySetupStep from "@/views/entity/tabs/entities/components/setup/StepComponent.vue";
import contentModerator from "@/views/contents/index.vue";
import contentAnalyse from "@/views/contents/components/reviewContent/index.vue";
import newContent from "@/views/contents/components/newContent/index.vue";
import scheduleAssistant from "@/views/scheduleAssistant/index.vue";
import users from "@/views/users/index.vue";
import socialMedia from "@/views/socialMedia/index.vue";
import settings from "@/views/settings/index.vue";

const routes = [
  {
    path: "/",
    redirect: "/auth/sign-in",
  },
  {
    path: "/reset-password",
    redirect: (to) => {
      const token = to.query.token;
      return {
        path: "/auth/reset-password/verify",
        query: { token },
      };
    },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import("@/views/contracts/PrivacyPolicy.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/auth",
    component: () => import("@/views/auth/AuthLayout.vue"),
    children: [
      {
        path: "sign-in",
        name: "signIn",
        component: () =>
          import("@/views/auth/signIn/components/SignInForm.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "sign-up",
        name: "signUp",
        component: () =>
          import("@/views/auth/signUp/components/SignUpForm.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "reset-password",
        name: "resetPasswordRequest",
        component: () => import("@/views/auth/resetPassword/RequestReset.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "reset-password/verify",
        name: "resetPassword",
        component: () =>
          import("@/views/auth/resetPassword/components/ResetPasswordForm.vue"),
        meta: { requiresAuth: false },
        beforeEnter: async (to, from, next) => {
          const token = to.query.token;

          if (!token) {
            responseHandlerService.handleError("Token bulunamadı");
            next({ name: "signIn" });
            return;
          }
          try {
            await axios.get(auth.validateResetToken, { params: { token } });
            next();
          } catch (error) {
            responseHandlerService.handleError(
              error.response?.data?.data?.message ||
                "Geçersiz veya süresi dolmuş şifre sıfırlama linki"
            );
            next({ name: "signIn" });
          }
        },
      },
      {
        path: "confirm-email",
        name: "confirmEmail",
        component: () => import("@/views/auth/confirmEmail/index.vue"),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/assets",
    name: "assets",
    component: entity,
    meta: { requiresAuth: true },
  },
  {
    path: "/assets/create",
    name: "createAsset",
    component: createAsset,
    meta: { requiresAuth: true },
  },
  {
    path: "/assets/setup",
    name: "assetSetup",
    component: entitySetup,
    meta: { requiresAuth: true, requiresCheckPhase: true },
    children: [
      {
        path: ":step",
        name: "setupStep",
        component: entitySetupStep,
        meta: { requiresAuth: true, requiresCheckPhase: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (!isValidStep(to.params.step)) {
            next({ name: "setupStep", params: { step: "basic-info" } });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/content-moderator",
    name: "contentModerator",
    component: contentModerator,
    meta: { requiresAuth: true },
  },
  {
    path: "/content-moderator/analyse/:id",
    name: "contentDetail",
    component: contentAnalyse,
    meta: { requiresAuth: true },
  },
  {
    path: "/new-content",
    name: "newContent",
    component: newContent,
    meta: { requiresAuth: true },
  },
  {
    path: "/content/scheduling/assistant",
    name: "scheduleAssistant",
    component: scheduleAssistant,
    meta: { requiresAuth: true },
  },
  {
    path: "/social-media",
    name: "socialMedia",
    component: socialMedia,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "users",
    component: users,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("@/views/notFound/index.vue"),
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const userData = authStore.userData;

  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  if (!userData.token) {
    next({ name: "signIn" });
    return;
  }

  if (userData.token && to.path.startsWith("/auth/")) {
    next({ name: "assets" });
    return;
  }

  const isValid = await authStore.validateToken();
  if (!isValid) {
    next({ name: "signIn" });
    return;
  }

  const assetStore = useAssetStore();

  if (to.meta.requiresCheckPhase && !assetStore.isCheckPhase) {
    next({ name: "assets" });
    return;
  }

  if (
    from.path.includes("/assets/setup") &&
    !to.path.includes("/assets/setup")
  ) {
    assetStore.exitCheckPhase();
  }

  next();
});

export default router;
