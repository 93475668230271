import { ref } from 'vue'
import * as XLSX from 'xlsx'
import { validateAndNormalizePriority } from '../utils/priorityUtils'
import { KeywordUtils } from '@/utils/keywordUtils'
import { responseHandlerService } from '@/plugins/responseHandlerService'

class KeywordImportManager {
  constructor(props, t) {
    this.maxKeywords = props.maxKeywords
    this.existingKeywords = [...props.keywords]
    this.t = t
    this.results = this.initializeResults()
  }

  initializeResults() {
    return {
      new: 0,
      reactivated: 0,
      updated: 0,
      unchanged: 0,
      invalid: 0,
      priorityNormalized: 0,
      skipped: 0,
      details: {
        newKeywords: [],
        updatedKeywords: [],
        unchangedKeywords: [],
        skippedKeywords: [],
        reactivatedKeywords: [],
        priorityNormalizedKeywords: [],
        invalidKeywords: [],
      },
    }
  }

  async importFile(file) {
    try {
      const workbook = await this.readExcel(file)
      await this.processWorkbook(workbook)
      this.showResults(workbook.SheetNames.length)

      return {
        success: true,
        keywords: this.existingKeywords,
        results: this.results,
      }
    } catch (error) {
      console.error('Import failed:', error)
      responseHandlerService.errorHandler(this.t('settings.components.configuration.components.keywordsTable.importError'))
      return { success: false, error }
    }
  }

  async readExcel(file) {
    try {
      const buffer = await file.arrayBuffer()
      return XLSX.read(buffer, {
        type: 'array',
        cellDates: true,
        cellStyles: true,
      })
    } catch (error) {
      throw new Error('Excel reading error: ' + error.message)
    }
  }

  async processWorkbook(workbook) {
    const allKeywords = []

    // Sadece ikinci sheet'i işle (index 1)
    if (workbook.SheetNames.length > 1) {
      const sheetName = workbook.SheetNames[1] // İkinci sheet
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

      if (rows.length <= 1) {
        responseHandlerService.warningHandler(this.t('settings.components.configuration.components.keywordsTable.emptySheet'))
        return
      }

      // İlk satır başlık satırı, onu atlıyoruz
      const validRows = rows.slice(1).filter(row =>
          row && row[0] && row[0].toString().trim() !== '',
      )

      const sheetKeywords = this.processSheet(validRows, 1)
      allKeywords.push(...sheetKeywords)
    } else {
      responseHandlerService.warningHandler(this.t('settings.components.configuration.components.keywordsTable.noSecondSheet'))
      return
    }

    await this.processKeywords(allKeywords)
  }

  processSheet(rows, sheetIndex) {
    const keywords = []

    rows.forEach((row, rowIndex) => {
      const rowInfo = {
        sheet: sheetIndex + 1,
        row: rowIndex + 2, // +2 çünkü 0-based index ve başlık satırını atladık
      }

      const result = this.validateRow(row)
      if (result.valid) {
        keywords.push({ ...result.data, rowInfo })
      } else {
        this.addInvalidResult(result.reason, rowInfo)
      }
    })

    return keywords
  }

  validateRow(row) {
    const keyword = row[0]?.toString().trim()
    const priority = (!row[1] || row[1] === '') ? null : row[1]
    const categoryId = (!row[2] || row[2] === '') ? null : row[2]

    if (!keyword) {
      return { valid: false, reason: 'empty_keyword' }
    }

    return {
      valid: true,
      data: { keyword, priority, categoryId },
    }
  }

  async processKeywords(keywords) {
    // Limit kontrolü
    if (this.existingKeywords.length + keywords.length > this.maxKeywords) {
      const availableSlots = this.maxKeywords - this.existingKeywords.length
      keywords.slice(availableSlots).forEach(kw => {
        this.addSkippedResult(kw, 'keyword_limit_exceeded')
      })
      keywords = keywords.slice(0, availableSlots)
    }

    for (const keywordData of keywords) {
      const result = this.processKeyword(keywordData)
      this.updateResults(result, keywordData.rowInfo)
    }
  }

  processKeyword(data) {
    const normalizedKeyword = KeywordUtils.normalize(data.keyword)
    const priorityResult = validateAndNormalizePriority(data.priority)

    if (!normalizedKeyword) {
      return { type: 'invalid', reason: 'invalid_keyword_format' }
    }

    // Aktif keywordlerde ara
    const existing = this.findExistingKeyword(normalizedKeyword)
    if (existing) {
      return this.updateExistingKeyword(existing, priorityResult, data.categoryId)
    }

    // Silinmiş keywordlerde ara (state=3)
    const deleted = this.findDeletedKeyword(normalizedKeyword)
    if (deleted) {
      return this.reactivateKeyword(deleted, priorityResult, data.categoryId)
    }

    // Yeni keyword oluştur
    return this.createNewKeyword(normalizedKeyword, priorityResult, data.categoryId)
  }

  findExistingKeyword(keyword) {
    return this.existingKeywords.find(k =>
        k.state !== 3 && KeywordUtils.normalize(k.name) === keyword,
    )
  }

  findDeletedKeyword(keyword) {
    return this.existingKeywords.find(k =>
        k.state === 3 && KeywordUtils.normalize(k.name) === keyword,
    )
  }

  updateExistingKeyword(existing, priorityResult, categoryId) {
    const hasChanges =
        existing.priority !== priorityResult.normalized ||
        existing.categoryId !== categoryId

    if (!hasChanges) {
      return { type: 'unchanged', keyword: existing }
    }

    const updated = {
      ...existing,
      priority: priorityResult.normalized,
      categoryId,
      state: existing.state === 1 ? 4 : existing.state,
    }

    // Mevcut keyword'ü güncelle
    const index = this.existingKeywords.findIndex(k => k.name === existing.name)
    if (index !== -1) {
      this.existingKeywords[index] = updated
    }

    return {
      type: 'updated',
      keyword: updated,
      priorityChange: priorityResult,
    }
  }

  reactivateKeyword(keyword, priorityResult, categoryId) {
    const reactivated = {
      ...keyword,
      state: 4,
      priority: priorityResult.normalized,
      categoryId,
    }

    // Mevcut keyword'ü güncelle
    const index = this.existingKeywords.findIndex(k => k.name === keyword.name)
    if (index !== -1) {
      this.existingKeywords[index] = reactivated
    }

    return {
      type: 'reactivated',
      keyword: reactivated,
      priorityChange: priorityResult,
    }
  }

  createNewKeyword(keyword, priorityResult, categoryId) {
    const newKeyword = {
      name: keyword,
      state: 2,
      priority: priorityResult.normalized,
      categoryId,
    }

    this.existingKeywords.push(newKeyword)

    return {
      type: 'new',
      keyword: newKeyword,
      priorityChange: priorityResult,
    }
  }

  updateResults(result, rowInfo) {
    switch (result.type) {
      case 'new':
        this.results.new++
        this.results.details.newKeywords.push({ ...result.keyword, rowInfo })
        break
      case 'updated':
        this.results.updated++
        this.results.details.updatedKeywords.push({ ...result.keyword, rowInfo })
        break
      case 'reactivated':
        this.results.reactivated++
        this.results.details.reactivatedKeywords.push({ ...result.keyword, rowInfo })
        break
      case 'unchanged':
        this.results.unchanged++
        this.results.details.unchangedKeywords.push({ ...result.keyword, rowInfo })
        break
    }

    if (result.priorityChange?.wasNormalized) {
      this.results.priorityNormalized++
      this.results.details.priorityNormalizedKeywords.push({
        ...result.keyword,
        originalPriority: result.priorityChange.original,
        normalizedPriority: result.priorityChange.normalized,
        rowInfo,
      })
    }
  }

  addInvalidResult(reason, rowInfo) {
    this.results.invalid++
    this.results.details.invalidKeywords.push({ reason, rowInfo })
  }

  addSkippedResult(keyword, reason) {
    this.results.skipped++
    this.results.details.skippedKeywords.push({ ...keyword, reason })
  }

  showResults(sheetCount) {
    let hasSuccessMessage = false

    if (this.results.priorityNormalized > 0) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.priorityNormalized',
              { count: this.results.priorityNormalized },
          ),
      )
    }

    if (this.results.invalid > 0) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.invalidRows',
              { count: this.results.invalid },
          ),
      )
    }

    if (this.results.skipped > 0) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.limitExceeded',
              { count: this.results.skipped, max: this.maxKeywords },
          ),
      )
    }

    ['new', 'reactivated', 'updated'].forEach((type) => {
      if (this.results[type] > 0) {
        responseHandlerService.successHandler(
            this.t(
                `settings.components.configuration.components.keywordsTable.keywords${type.charAt(0).toUpperCase() + type.slice(1)}`,
                { count: this.results[type] },
            ),
        )
        hasSuccessMessage = true
      }
    })

    if (!hasSuccessMessage && this.results.invalid === 0 && this.results.skipped === 0) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.noChanges'),
      )
    }
  }
}

// Basitleştirilmiş composable
export function useKeywordImport(props, emit, t) {
  const importResults = ref(null)

  const handleFileUpload = async (file) => {
    const manager = new KeywordImportManager(props, t)
    const result = await manager.importFile(file)

    if (result.success) {
      importResults.value = result.results
      emit('update:keywords', result.keywords)
    }

    return result
  }

  return {
    importResults,
    handleFileUpload,
  }
}