<template>
  <div class="min-h-screen w-full relative">
    <template v-if="showLayout">
      <top-navbar @show-project-modal="handleShowProjectModal" />

      <!-- Backdrop -->
      <div
        v-show="navigation.isSidebarOpen && showSidebarToggle"
        class="fixed inset-0 bg-black/30 backdrop-blur-sm z-[900] transition-all duration-300"
        @click="navigation.closeSidebar"
      />

      <!-- Sidebar -->
      <nav-sidebar
        v-model="navigation.isSidebarOpen"
        :items="navigation.menuItems"
        :show-sidebar-toggle="showSidebarToggle"
        :is-mobile="isMobile"
        :open-submenu-index="navigation.openSubmenuIndex"
        :show-submenu="navigation.showSubmenu"
        :show-items-for-routes="showItemsForRoutes"
        :current-project="currentProject"
        :profile-name="profileName"
        @menu-enter="handleMenuEnter"
        @menu-leave="handleMenuLeave"
        @menu-click="handleMenuClick"
        @sub-item-click="handleSubItemClick"
        @show-project-modal="handleShowProjectModal"
        @set-menu-ref="(payload) => (menuRefs[payload.index] = payload.el)"
      />

      <!-- Desktop Floating Submenu -->
      <teleport to="body">
        <transition
          enter-active-class="transition-all ease-out duration-300"
          enter-from-class="opacity-0 translate-x-2"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition-all ease-in duration-200"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-2"
        >
          <floating-submenu
            v-if="!isMobile && navigation.showSubmenu && activeSubmenu"
            ref="submenuRef"
            :style="submenuPosition"
            :submenu-items="activeSubmenu"
            :current-menu-item="currentMenuItem"
            @mouseenter="handleSubmenuEnter"
            @mouseleave="handleMenuLeave"
            @click="handleSubItemClick"
          />
        </transition>
      </teleport>
    </template>

    <!-- Main Content -->
    <div
      :class="{
        'min-h-screen': true,
        'pt-14': showLayout,
      }"
    >
      <slot />
    </div>
  </div>

  <!-- Project Selection Modal -->
  <ProjectSelectionModal v-model="showProjectSelectionModal" />
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import TopNavbar from "./topNavbar/index.vue";
import NavSidebar from "./sidebar/index.vue";
import FloatingSubmenu from "./floatingSubmenu/index.vue";
import ProjectSelectionModal from "../layout/topNavbar/components/ProjectSelectionModal.vue";
import { useNavigationStore } from "@/stores/navigationStore";
import { useAssetStore } from "@/stores/asset";
import { useAuthStore } from "@/stores/auth";

const route = useRoute();
const router = useRouter();
const navigation = useNavigationStore();
const assetStore = useAssetStore();
const authStore = useAuthStore();

// Constants
const SUBMENU_DELAY = 150;
const CLOSE_DELAY = 200;
const SIDEBAR_WIDTH = 256;
const SUBMENU_OFFSET = 12;
const MOBILE_BREAKPOINT = 768;

// Refs
const menuRefs = ref({});
const submenuRef = ref(null);
const currentMenuItem = ref(null);
const activeSubmenu = ref(null);
const submenuPosition = ref({ top: "0px", left: "0px" });
const submenuTimeout = ref(null);
const closeTimeout = ref(null);
const isMobile = ref(false);
const showProjectSelectionModal = ref(false);

// Computed
const profileName = computed(() => authStore.userData.fullname);

const showLayout = computed(() => {
  if (route.path.startsWith("/auth/")) return false;
  if (route.name === "notFound") return false;
  if (route.path.startsWith("/privacy-policy")) return false;
  return true;
});

const showSidebarToggle = computed(() => {
  if (route.path.startsWith("/assets")) return false;
  if (route.path.startsWith("/users")) return false;
  if (route.path === "/") return false;
  return true;
});

const showItemsForRoutes = computed(() => {
  return (
    !route.path.startsWith("/auth/") &&
    route.name !== "notFound" &&
    !route.path.startsWith("/assets")
  );
});

const currentProject = computed(() => {
  return assetStore.assetName || "Select Project";
});

// Methods
const handleShowProjectModal = () => {
  showProjectSelectionModal.value = true;
};

const handleMenuEnter = ({ item, index, event }) => {
  if (!item.subItems || isMobile.value) return;

  clearTimeout(submenuTimeout.value);
  clearTimeout(closeTimeout.value);

  submenuTimeout.value = setTimeout(() => {
    const menuElement = menuRefs.value[index];
    if (!menuElement) return;

    currentMenuItem.value = item;
    activeSubmenu.value = item.subItems;
    navigation.setSubmenuState(true, index);

    const rect = menuElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const submenuHeight = item.subItems.length * 36 + 16;

    let top = rect.top;
    if (top + submenuHeight > windowHeight) {
      top = windowHeight - submenuHeight - 12;
    }

    submenuPosition.value = {
      top: `${top}px`,
      left: `${SIDEBAR_WIDTH + SUBMENU_OFFSET}px`,
    };
  }, SUBMENU_DELAY);
};

const handleMenuLeave = () => {
  if (isMobile.value) return;
  clearTimeout(submenuTimeout.value);
  startCloseTimer();
};

const handleSubmenuEnter = () => {
  if (isMobile.value) return;
  clearTimeout(closeTimeout.value);
};

const handleSubmenuLeave = () => {
  if (isMobile.value) return;
  startCloseTimer();
};

const startCloseTimer = () => {
  if (isMobile.value) return;
  clearTimeout(closeTimeout.value);
  closeTimeout.value = setTimeout(() => {
    navigation.closeSubmenu();
    setTimeout(() => {
      activeSubmenu.value = null;
      currentMenuItem.value = null;
    }, 200);
  }, CLOSE_DELAY);
};

const handleMenuClick = ({ item, index }) => {
  if (isMobile.value && item.subItems) {
    if (navigation.openSubmenuIndex === index) {
      navigation.closeSubmenu();
    } else {
      currentMenuItem.value = item;
      activeSubmenu.value = item.subItems;
      navigation.setSubmenuState(true, index);
    }
  } else if (!item.subItems) {
    router.push(item.path);
    navigation.closeSidebar();
  }
};

const handleSubItemClick = () => {
  navigation.closeSubmenu();
  navigation.closeSidebar();
};

const checkMobile = () => {
  isMobile.value = window.innerWidth < MOBILE_BREAKPOINT;
  if (isMobile.value) {
    navigation.closeSubmenu();
  }
};

// Lifecycle
onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
  window.addEventListener("scroll", updateSubmenuPosition);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", checkMobile);
  window.removeEventListener("scroll", updateSubmenuPosition);
  clearTimeout(submenuTimeout.value);
  clearTimeout(closeTimeout.value);
});

const updateSubmenuPosition = () => {
  if (!isMobile.value && navigation.openSubmenuIndex !== null) {
    const menuElement = menuRefs.value[navigation.openSubmenuIndex];
    if (menuElement && activeSubmenu.value) {
      const rect = menuElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const submenuHeight = activeSubmenu.value.length * 36 + 16;

      let top = rect.top;
      if (top + submenuHeight > windowHeight) {
        top = windowHeight - submenuHeight - 12;
      }

      submenuPosition.value = {
        top: `${top}px`,
        left: `${SIDEBAR_WIDTH + SUBMENU_OFFSET}px`,
      };
    }
  }
};
</script>
