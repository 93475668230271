<template>
  <VueFinalModal
    v-model="isModalOpen"
    :clickToClose="!loading"
    :escToClose="!loading"
    @closed="handleModalClosed"
    class="flex justify-center items-center"
    contentClass="flex flex-col max-w-xl bg-white rounded-lg shadow-xl w-[650px]"
    contentTransition="vfm-slide-down"
    id="serviceTypeModal"
  >
    <div class="h-full flex flex-col w-full">
      <div
        class="w-full flex items-center justify-between sticky top-0 bg-gradient-to-r from-secondary to-primary z-10 px-4 py-3 rounded-t-lg"
      >
        <h2 class="text-lg font-bold text-white">
          {{ t("assets.components.create.components.serviceTypeModal.title") }}
        </h2>
        <span
          @click="$emit('update:modelValue', false)"
          class="material-symbols-outlined text-white cursor-pointer hover:opacity-75"
        >
          close
        </span>
      </div>

      <div
        class="flex flex-col space-y-3 w-full p-4 max-h-[500px] overflow-auto thin-scrollbar"
      >
        <div class="grid grid-cols-1 gap-3">
          <div
            v-for="(type, index) in assetTypes"
            :key="index"
            class="group transition-all duration-300 py-3 px-4 border-2 rounded-lg cursor-pointer w-full flex justify-between items-center hover:shadow-md"
            :class="{
              'border-primary shadow-md bg-primary/5':
                serviceType === type.value,
              'border-gray-200 hover:border-gray-300':
                serviceType !== type.value,
              'cursor-not-allowed opacity-50 hover:shadow-none': type.disabled,
            }"
            @click="handleTypeClick(type)"
          >
            <div class="flex items-center space-x-3">
              <div
                class="w-10 h-10 rounded-full flex items-center justify-center"
                :class="{
                  'bg-primary/10': serviceType === type.value,
                  'bg-gray-100 group-hover:bg-gray-200':
                    serviceType !== type.value,
                }"
              >
                <span
                  class="material-symbols-outlined text-xl"
                  :class="{
                    'text-primary': serviceType === type.value,
                    'text-gray-600': serviceType !== type.value,
                  }"
                >
                  {{ type.icon }}
                </span>
              </div>
              <div>
                <span class="flex items-center space-x-1">
                  <h4 class="font-semibold">
                    {{
                      t(
                        `assets.components.create.components.serviceTypeModal.assetTypes.${type.text.toLowerCase()}.text`
                      )
                    }}
                  </h4>
                  <VTooltip
                    teleportTo="#serviceTypeModal"
                    v-if="!type.disabled"
                    :title="
                      t(`common.tooltipInformations.${type.text.toLowerCase()}`)
                    "
                    information
                  >
                    <template #content>
                      <icon-component
                        icon="info"
                        iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
                      />
                    </template>
                  </VTooltip>
                </span>

                <p class="text-sm text-gray-500 mt-0.5">
                  {{
                    t(
                      `assets.components.create.components.serviceTypeModal.assetTypes.${type.text.toLowerCase()}.description`
                    )
                  }}
                </p>
                <div v-if="type.features" class="flex flex-wrap gap-1.5 mt-1.5">
                  <span
                    v-for="feature in type.features"
                    :key="feature"
                    class="text-xs px-2 py-0.5 rounded-full bg-gray-100 text-gray-600"
                  >
                    {{
                      t(
                        `assets.components.create.components.serviceTypeModal.features.${type.text.toLowerCase()}.${feature}`
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div class="flex-shrink-0">
              <div
                class="w-5 h-5 rounded-full border-2 flex items-center justify-center"
                :class="{
                  'border-primary': serviceType === type.value,
                  'border-gray-300': serviceType !== type.value,
                }"
              >
                <div
                  v-if="serviceType === type.value"
                  class="w-2.5 h-2.5 rounded-full bg-primary"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between space-x-2 pt-3">
          <customButton
            color="secondary"
            type="button"
            variation="outline"
            :buttonText="
              t(
                'assets.components.create.components.serviceTypeModal.buttons.cancel'
              )
            "
            :isDisabled="false"
            :fullWidth="true"
            @click="$emit('update:modelValue', false)"
          />
          <customButton
            @click="proceedToNextStep"
            :buttonText="
              t(
                'assets.components.create.components.serviceTypeModal.buttons.nextStep'
              )
            "
            :isDisabled="!serviceType"
            :loading="loading"
            :additionalButtonClass="loading ? '!bg-gray-400' : '!bg-primary'"
            :fullWidth="true"
          />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from "vue";
import { useAssetStore } from "@/stores/asset";
import { useRouter } from "vue-router";
import { VueFinalModal } from "vue-final-modal";
import customButton from "@/components/base/buttons/VButton.vue";
import { useI18n } from "vue-i18n";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import { useNavigationStore } from "@/stores/navigationStore";
import { useStepsStore } from "@/stores/steps";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "opened"]);
const { t } = useI18n();
const assetStore = useAssetStore();
const stepsStore = useStepsStore();
const router = useRouter();
const loading = ref(false);
const serviceType = ref(null);
const isModalOpen = ref(props.modelValue);

const assetTypes = ref([
  {
    value: 1,
    text: "service",
    icon: "linked_services",
    features: ["apiIntegration", "autoUpdate", "realtime"],
    disabled: false,
  },
  {
    value: 2,
    text: "crawler",
    icon: "travel_explore",
    features: ["customCrawling", "dataExtraction", "scheduling"],
    disabled: false,
  },
  {
    value: 3,
    text: "integration",
    icon: "integration_instructions",
    features: ["wordpress", "shopify", "woocommerce", "otherCms"],
    disabled: true,
  },
]);

watch(
  () => props.modelValue,
  (newValue) => {
    isModalOpen.value = newValue;
  }
);

watch(isModalOpen, (newValue) => {
  emit("update:modelValue", newValue);
});

const proceedToNextStep = async () => {
  loading.value = true;
  try {
    await stepsStore.resetStepManagement();

    await assetStore.resetAssetData();
    await assetStore.setAssetServiceType(serviceType.value);

    await router.push("/assets/create");
  } catch (error) {
    responseHandlerService.handleError(error);
  } finally {
    loading.value = false;
  }
};

const handleModalClosed = () => {
  serviceType.value = null;
  emit("update:modelValue", false);
};

const handleTypeClick = (type) => {
  if (type.disabled)
    responseHandlerService.handleWarning(
      t(
        "assets.components.create.components.serviceTypeModal.warnings.comingSoon"
      )
    );
  else serviceType.value = type.value;
};
</script>
