<template>
  <div class="layout-spacing">
    <!-- Header -->
    <div class="mb-8 bg-white p-6 rounded-md shadow-md">
      <div class="flex items-center">
        <h1 class="text-2xl font-semibold text-gray-900">
          {{ t(currentStepTitle) }}
        </h1>
      </div>

      <p class="mt-2 text-sm text-gray-600">{{ t(currentStepDescription) }}</p>
    </div>

    <router-view
      @information="setupStore.nextStep++"
      @stepComplete="handleStepComplete"
      @completed="completeSetup"
    />
    <!-- Navigation buttons -->
    <div
      class="mt-8"
      :class="{
        'flex justify-end': !previousStep,
        'flex justify-between': previousStep,
      }"
    >
      <VButton
        v-if="previousStep"
        variation="outline"
        color="neutral"
        :button-text="$t('common.previous')"
        @click="goToPreviousStep"
      />
      <VButton
        v-if="nextStep && currentStepOrder !== 1"
        color="primary"
        :button-text="$t('common.next')"
        :loading-text="$t('general.loading')"
        :is-loading="loading"
        @click="setupStore.nextStep++"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAssetStore } from "@/stores/asset";
import { setupSteps, getNextStep, getPreviousStep } from "./config";
import axios from "@/plugins/axiosInstance";
import { assets } from "@/networking/urlManager";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import VButton from "@/components/base/buttons/VButton.vue";
import { useSetupStore } from "@/stores/assetSetup";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const assetStore = useAssetStore();
const setupStore = useSetupStore();
const loading = ref(false);
const currentStep = computed(() => route.params.step);
const nextStep = computed(() => getNextStep(currentStep.value));
const previousStep = computed(() => getPreviousStep(currentStep.value));
const currentStepTitle = computed(() => setupSteps[currentStep.value].title);
const currentStepOrder = computed(() => setupSteps[currentStep.value].order);
const currentStepDescription = computed(
  () => setupSteps[currentStep.value].description
);
const handleStepComplete = (isValid) => {
  if (isValid) {
    router.push({
      name: "setupStep",
      params: { step: nextStep.value },
    });
  }
};

const goToPreviousStep = () => {
  if (previousStep.value) {
    router.push({
      name: "setupStep",
      params: { step: previousStep.value },
    });
  }
};

const completeSetup = async () => {
  loading.value = true;
  try {
    await axios.post(assets.changeAssetSetup, {
      assetId: assetStore.assetId,
    });
    await assetStore.exitCheckPhase();
    responseHandlerService.handleSuccess("Setup başarıyla tamamlandı");
    await router.push({ name: "assets" });
  } catch (error) {
    responseHandlerService.handleError(error);
  } finally {
    loading.value = false;
  }
};
</script>
