<template>
  <div class="flex flex-col lg:flex-row gap-6">
    <div class="w-full lg:w-3/12">
      <div
        class="bg-white p-4 rounded-lg border border-gray-100 shadow-md h-full"
      >
        <div class="mb-4 flex items-center justify-center">
          <h3 class="text-lg font-semibold text-gray-800 text-center">
            {{ t("assets.dashboard.aiReport.title") }}
          </h3>
          <VTooltip
            :title="t('common.tooltipInformations.reportAssistant')"
            information
          >
            <template #content>
              <icon-component
                icon="info"
                iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
              />
            </template>
          </VTooltip>
        </div>

        <!-- :is-loading="dashboardStore.isComponentLoading('chart')" -->
        <ReportStatus
          :reportStatusLoad="reportStatusLoad"
          :isDisabled="!props.chartData?.keywordData?.topByVolume?.length"
          :has-error="dashboardStore.hasRequirementError"
          :is-ready="dashboardStore.isReportReady"
          @create="fetchReport(true)"
          :is-loading="isReportProcessing"
          @retry="fetchReport(true)"
          @view-report="showReportModal = true"
        />
      </div>
    </div>

    <div class="w-full lg:w-9/12">
      <div class="bg-white p-4 rounded-lg border border-gray-100 shadow-md">
        <AssetsTable
          @selection-change="handleAssetSelection"
          @asset-data="handleAssetData"
        />
      </div>
    </div>

    <ReportPreviewModal
      v-model="showReportModal"
      :report-content="dashboardStore.chart.reportData"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import axios from "@/plugins/axiosInstance";
import { genaralReporting } from "@/networking/urlManager";
import { useDashboardStore } from "@/stores/dashboardOfAssets";
import AssetsTable from "../AssetsTable.vue";
import ReportStatus from "./components/ReportStatus.vue";
import ReportPreviewModal from "./components/ReportPreviewModal.vue";
import { useI18n } from "vue-i18n";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const props = defineProps({
  chartData: {
    type: Object,
    required: false,
  },
  reportStatusLoad:{
    type: Boolean,
    default: true
  }
});

const { t } = useI18n();
const emit = defineEmits(["selectionChange", "asset-data"]);
const dashboardStore = useDashboardStore();
const showReportModal = ref(false);
const isReportProcessing = ref(false);
const canFetchReport = computed(() => {
  const hasMetrics = !!props.chartData?.metricsData;
  const hasKeywords = !!props.chartData?.keywordData?.topByVolume?.length;
  const hasAssets = !!dashboardStore.chart.selectedAssets?.length;
  const canGenerate = dashboardStore.canGenerateReport;
  return canGenerate && hasMetrics && hasKeywords && hasAssets;
});


const fetchReport = async (force = false) => {
  if (isReportProcessing.value) return;
  
  if (!canFetchReport.value) {
    dashboardStore.resetReportStatus();
    return;
  }

  try {
    isReportProcessing.value = true;
    const response = await axios.post(genaralReporting.aiAnalysis, {
      data: {
        assetData: dashboardStore.chart.selectedAssets,
        keywordAndMetrics: props.chartData
      },
      language: dashboardStore.currentLanguage
    });
    dashboardStore.setReportData(response.data.data);
  } catch (error) {
    console.error("Report fetch error:", error);
    dashboardStore.setReportError(true); 
  } finally {
    isReportProcessing.value = false;
  }
};
// const fetchReport = async (force = false) => {
//   if (isReportProcessing.value) return;
//   isReportProcessing.value = true;

//   if (!canFetchReport.value) {
//     dashboardStore.resetReportStatus();
//     isReportProcessing.value = false;
//     return;
//   }

//   dashboardStore.setLoading("chart", true);

//   const requestData = {
//     assetData: dashboardStore.chart.selectedAssets,
//     keywordAndMetrics: props.chartData,
//   };

//   try {
//     const response = await axios.post(genaralReporting.aiAnalysis, {
//       data: requestData,
//       language: dashboardStore.currentLanguage,
//     });
//     dashboardStore.setReportData(response.data.data);
//   } catch (error) {
//     console.error("Report fetch error:", error);
//     dashboardStore.setReportError(true);
//   } finally {
//     dashboardStore.setLoading("chart", false);
//     isReportProcessing.value = false;
//   }
// };

const handleAssetSelection = (selectedAssets) => {
  dashboardStore.setSelectedAssets(selectedAssets);
  dashboardStore.resetReportStatus();
  emit("selectionChange", selectedAssets);
};

const handleAssetData = (data) => {
  dashboardStore.setChartData(data);
  emit("asset-data", data);
};

watch(canFetchReport, (canFetch) => {  
  if (canFetch && !isReportProcessing.value) {
    // fetchReport(true);
  }
});

watch(
  () => dashboardStore.currentLanguage,
  () => {    
    if (canFetchReport.value && !isReportProcessing.value) {
      // fetchReport(true);
    }
  }
);
</script>
