<template>
  <!-- Level 1 için ana tasarım -->
  <div v-if="category.level === 1" class="bg-white border rounded-xl hover:shadow-md transition-all duration-300 group">
    <div class="p-4 hover:bg-gradient-to-r hover:from-primary/5 hover:to-transparent">
      <div class="flex items-center justify-between">
        <!-- Left Side -->
        <div class="flex items-center space-x-3">
          <button v-if="category.has_children" @click="handleToggle" class="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center justify-center w-8 h-8 rounded-full hover:bg-primary/10" :title="isExpanded ? t('settings.components.configuration.components.categoryModal.item.actions.collapse') : t('settings.components.configuration.components.categoryModal.item.actions.expand')">
            <span class="material-symbols-outlined transform transition-transform duration-300" :class="{ 'rotate-90': isExpanded, 'text-primary': isExpanded }">
              chevron_right
            </span>
          </button>

          <div class="flex flex-col">
            <span class="font-medium text-gray-700">{{ category.name }}</span>
            <div class="flex items-center gap-2 text-xs text-gray-500">
              <span>{{ t('settings.components.configuration.components.categoryModal.item.level', { number: category.level }) }}</span>
              <template v-if="category.is_custom">
                <span class="w-1 h-1 rounded-full bg-gray-300"></span> <span class="px-1.5 py-0.5 bg-primary/10 text-primary rounded-full text-[10px] font-medium">
                  {{ t('settings.components.configuration.components.categoryModal.item.customBadge') }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <!-- Actions -->
        <div class="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button v-if="category.is_custom" @click="handleDelete" class="p-2 rounded-lg hover:bg-red-50 group/delete transition-colors duration-300" :class="isDeleting ? 'bg-red-50' : ''" :title="t('settings.components.configuration.components.categoryModal.item.actions.delete')">
            <span class="material-symbols-outlined !text-[20px] transition-colors duration-300" :class="[isDeleting ? 'text-red-500' : 'text-gray-400 group-hover/delete:text-red-500']">
              {{ isDeleting ? 'check_circle' : 'delete' }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <!-- Level 1 Alt kategoriler -->
    <div v-if="isExpanded && category.has_children" class="border-t bg-gray-50/40">
      <div v-if="isLoadingSub" class="py-4 flex justify-center">
        <span class="material-symbols-outlined animate-spin text-primary">progress_activity</span>
      </div>
      <div v-else-if="subCategories?.length" class="py-2">
        <CategoryItem v-for="subCategory in subCategories" :key="subCategory.id" :category="subCategory" :sub-categories="subCategoriesMap[subCategory.id]" :is-expanded="expandedCategories.includes(subCategory.id)" :is-loading-sub="loadingSubCategories === subCategory.id" :is-deleting="isDeletingId === subCategory.id" :expanded-categories="expandedCategories" :loading-sub-categories="loadingSubCategories" :is-deleting-id="isDeletingId" :sub-categories-map="subCategoriesMap" @toggle="$emit('toggle', $event)" @delete="$emit('delete', $event)"/>
      </div>
    </div>
  </div>

  <!-- Alt seviyeler için minimal tasarım -->
  <div v-else class="px-4 py-2 hover:bg-gray-100/70 rounded-md group">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-3">
        <button v-if="category.has_children" @click="handleToggle" class="text-gray-400 p-1.5 hover:bg-white rounded-full transition-colors group-hover:text-primary" :title="isExpanded ? t('settings.components.configuration.components.categoryModal.item.actions.collapse') : t('settings.components.configuration.components.categoryModal.item.actions.expand')">
          <span class="material-symbols-outlined !text-[20px] transform transition-transform duration-300" :class="{ 'rotate-90': isExpanded, 'text-primary': isExpanded }">
            chevron_right
          </span>
        </button>
        <div class="flex items-center gap-2">
          <span class="text-sm text-gray-600">{{ category.name }}</span> <span class="text-xs text-gray-400">
            ({{ t('settings.components.configuration.components.categoryModal.item.level', { number: category.level }) }})
          </span> <span v-if="category.is_custom" class="px-1.5 py-0.5 bg-primary/10 text-primary text-[10px] rounded-full">
            {{ t('settings.components.configuration.components.categoryModal.item.customBadge') }}
          </span>
        </div>
      </div>

      <button v-if="category.is_custom" @click="handleDelete" class="opacity-0 group-hover:opacity-100 p-2 rounded-lg hover:bg-red-50 group/delete transition-all duration-300" :class="isDeleting ? 'opacity-100 bg-red-50' : ''" :title="t('settings.components.configuration.components.categoryModal.item.actions.delete')">
        <span class="material-symbols-outlined !text-[20px] transition-colors duration-300" :class="[isDeleting ? 'text-red-500' : 'text-gray-400 group-hover/delete:text-red-500']">
          {{ isDeleting ? 'check_circle' : 'delete' }}
        </span>
      </button>
    </div>

    <!-- Alt kategori recursive render -->
    <div v-if="isExpanded && category.has_children" class="pl-8 mt-1 border-l border-gray-200 ml-2">
      <div v-if="isLoadingSub" class="py-2 flex justify-start pl-2">
        <span class="material-symbols-outlined animate-spin text-primary !text-sm">progress_activity</span>
      </div>
      <template v-else-if="subCategories?.length">
        <CategoryItem v-for="subCategory in subCategories" :key="subCategory.id" :category="subCategory" :sub-categories="subCategoriesMap[subCategory.id]" :is-expanded="expandedCategories.includes(subCategory.id)" :is-loading-sub="loadingSubCategories === subCategory.id" :is-deleting="isDeletingId === subCategory.id" :expanded-categories="expandedCategories" :loading-sub-categories="loadingSubCategories" :is-deleting-id="isDeletingId" :sub-categories-map="subCategoriesMap" @toggle="$emit('toggle', $event)" @delete="$emit('delete', $event)"/>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  category: {
    type: Object,
    required: true,
  },
  subCategories: {
    type: Array,
    default: () => [],
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
  isLoadingSub: {
    type: Boolean,
    default: false,
  },
  isDeleting: {
    type: Boolean,
    default: false,
  },
  expandedCategories: {
    type: Array,
    required: true,
  },
  loadingSubCategories: [String, Number, null],
  isDeletingId: [String, Number, null],
  subCategoriesMap: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['toggle', 'delete'])

const handleToggle = () => {
  emit('toggle', {
    id: props.category.id,
    isCustom: props.category.is_custom,
    isLevel1: props.category.level === 1,
  })
}

const handleDelete = () => {
  emit('delete', props.category)
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease-in-out;
  max-height: 1000px;
  opacity: 1;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>