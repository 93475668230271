import * as yup from 'yup'

// Sosyal medya URL'leri için regex desenleri
const socialMediaPatterns = {
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
  instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/i,
  linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/.+$/i,
  twitter: /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/.+$/i,
}

// Genel URL doğrulaması için regex deseni
const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

// Uluslararası telefon numaraları için regex deseni
const phoneRegex = /^\+\d{1,3}[0-9]{6,14}$/

// Şema oluşturma fonksiyonu
export const createSettingsSchema = (t) => {
  return yup.object().shape({
    aboutus: yup
        .string()
        .max(3000, t('form.errors.maxLength', { length: 3000 }))
        .required(t('form.errors.required'))
        .nullable(),

    facebookUrl: yup
        .string()
        .nullable()
        .transform(value => value?.trim() || null) // Boş değerleri null yap
        .test('valid-facebook', t('form.errors.invalidFacebookUrl'), value => {
          if (!value) return true // Boş değer kabul edilir
          return socialMediaPatterns.facebook.test(value)
        }),

    instagramUrl: yup
        .string()
        .nullable()
        .transform(value => value?.trim() || null)
        .test('valid-instagram', t('form.errors.invalidInstagramUrl'), value => {
          if (!value) return true
          return socialMediaPatterns.instagram.test(value)
        }),

    twitterUrl: yup
        .string()
        .nullable()
        .transform(value => value?.trim() || null)
        .test('valid-twitter', t('form.errors.invalidTwitterUrl'), value => {
          if (!value) return true
          return socialMediaPatterns.twitter.test(value)
        }),

    linkedinUrl: yup
        .string()
        .nullable()
        .transform(value => value?.trim() || null)
        .test('valid-linkedin', t('form.errors.invalidLinkedinUrl'), value => {
          if (!value) return true
          return socialMediaPatterns.linkedin.test(value)
        }),

    phone: yup
        .string()
        .nullable()
        .transform(value => value?.replace(/[\s\-()]/g, '') || null) // Boşluk ve özel karakterleri temizle
        .test('valid-phone', t('form.errors.invalidPhone'), value => {
          if (!value) return true // Boşsa kontrol etme
          return phoneRegex.test(value) // Uluslararası formatı doğrula
        }),

    wphone: yup
        .string()
        .nullable()
        .transform(value => value?.replace(/[\s\-()]/g, '') || null)
        .test('valid-wphone', t('form.errors.invalidPhone'), value => {
          if (!value) return true
          return phoneRegex.test(value)
        }),
  })
}
