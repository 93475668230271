<template>
  <div
    class="component-layout bg-white p-6 rounded-lg shadow-lg"
    style="height: 65vh"
  >
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
      <!-- Sol Sütun: İçerik Oluşturma Formu -->
      <div
        class="bg-white p-6 rounded-md shadow-md flex flex-col justify-between overflow-y-auto"
      >
        <div>
          <span class="flex items-center space-x-1 mb-3">
            <h3 class="text-xl font-semibold text-gray-800 ">
              {{ t("socialMedia.components.createPost.selectPlatform") }}
            </h3>
            <VTooltip
              :title="t('common.tooltipInformations.selectPlatform')"
              information
            >
              <template #content>
                <icon-component
                  icon="info"
                  iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"
                />
              </template>
            </VTooltip>
          </span>
          <div class="flex space-x-4">
            <button
              v-for="(value, key) in SocialPlatform"
              :key="value"
              @click="togglePlatform(value)"
              :class="[
                'flex items-center justify-center w-12 h-12 rounded-full text-sm font-medium transition-all duration-200',
                selectedPlatforms.includes(value)
                  ? 'bg-white shadow-xl ring-2 ring-indigo-300 scale-110'
                  : 'bg-gray-200 text-gray-600 hover:bg-gray-300',
              ]"
            >
              <img
                :src="
                  require(
                    `@/assets/icons/${getPlatformKey(value).toLowerCase()}.svg`
                  )
                "
                :alt="key"
                class="w-6 h-6"
              />
            </button>
          </div>
        </div>

        <LanguageSelector
          v-model="languageId"
          :label="t('socialMedia.components.createPost.languages')"
          :description="
            t('socialMedia.components.createPost.languageDescription')
          "
          :additionalInputClass="'bg-white rounded-md shadow-sm'"
        />

        <TagInput
          v-model="keywords"
          :label="t('socialMedia.components.createPost.keywords')"
          :description="
            t('socialMedia.components.createPost.keywordsDescription')
          "
          :maxCount="40"
          :showSearchandFileUpload="false"
          color="primary"
          containerClass="bg-white rounded-md shadow-sm"
        />

        <CustomButton
          :buttonText="t('socialMedia.components.createPost.generateContent')"
          :isLoading="isGenerating"
          :isDisabled="!canGenerate || isGenerating"
          @click="generateContent"
          color="primary"
          variation="solid"
          :fullWidth="true"
          size="sm"
          :class="!canGenerate ? 'opacity-50 cursor-not-allowed' : ''"
        />
      </div>

      <!-- Sağ Sütun: Oluşturulan İçerik -->
      <div
        class="bg-white rounded-md shadow-md flex flex-col max-h-[calc(65vh-3rem)]"
      >
        <div class="flex justify-between items-center p-4 border-b">
          <h3 class="text-xl font-semibold text-gray-800">
            {{ t("socialMedia.components.createPost.generatedContent") }}
          </h3>
          <div class="space-x-2 flex">
            <CustomButton
              :buttonText="t('socialMedia.components.createPost.save')"
              :isLoading="isSaving"
              :isDisabled="!canSave"
              @click="savePost"
              color="primary"
              variation="solid"
              size="sm"
              :class="!canSave ? 'opacity-50 cursor-not-allowed' : ''"
            />
            <CustomButton
              :buttonText="t('socialMedia.components.createPost.share')"
              :isLoading="isPublishing"
              :isDisabled="!canPublish"
              @click="publishPost"
              color="secondary"
              variation="solid"
              size="sm"
              :class="!canPublish ? 'opacity-50 cursor-not-allowed' : ''"
              />
              <!-- :disable-gray="!canPublish" -->
          </div>
        </div>
        <div class="flex-grow overflow-y-auto thin-scrollbar">
          <div v-if="generatedContent" class="p-4 space-y-4">
            <div class="flex items-center space-x-3 mb-2">
              <div
                v-for="platform in selectedPlatforms"
                :key="platform"
                class="flex items-center"
              >
                <img
                  :src="
                    require(
                      `@/assets/icons/${getPlatformKey(platform).toLowerCase()}.svg`
                    )
                  "
                  :alt="getPlatformKey(platform)"
                  class="w-10 h-10 rounded-full border-2 border-gray-200 p-1 mr-2"
                />
              </div>
              <div>
                <h4 class="font-semibold text-gray-800">
                  {{ t("socialMedia.components.createPost.selectedPlatforms") }}
                </h4>
                <p class="text-sm text-gray-500">{{ getCurrentDate() }}</p>
              </div>
            </div>
            <p class="text-gray-800 whitespace-pre-line">
              {{ generatedContent.message }}
            </p>
            <p class="text-blue-700 whitespace-pre-line cursor-pointer">
              {{ generatedContent.hashtags.map((r) => r).join(" ") }}
            </p>
            <div v-if="generatedContent.link" class="mt-2">
              <a
                :href="generatedContent.link"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue-600 hover:underline text-xs"
              >
                {{ truncatedLink }}
              </a>
              <div class="mt-2 border rounded-md overflow-hidden">
                <ImageComponent
                  :imageUrls="[generatedContent.link]"
                  :contentLoading="isGenerating"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="flex items-center justify-center h-full text-gray-500 italic p-4"
          >
            {{ t("socialMedia.components.createPost.contentPlaceholder") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import axios from "@/plugins/axiosInstance";
import { socialMedia } from "@/networking/urlManager";
import TagInput from "@/components/base/inputs/VTagInput.vue";
import LanguageSelector from "@/components/base/inputs/VLanguageSelector.vue";
import CustomButton from "@/components/base/buttons/VButton.vue";
import ImageComponent from "@/components/common/VImage.vue";
import { responseHandlerService } from "@/plugins/responseHandlerService";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useRouter } from "vue-router";
import { useAssetStore } from "@/stores/asset";
import { useFeatureStore } from "@/stores/featureStore";
import VTooltip from "@/components/common/VTooltip.vue";
import IconComponent from "@/components/common/VIcon.vue";

const { t } = useI18n();
dayjs.extend(localizedFormat);

const props = defineProps({
  socialAuths: {
    type: Object,
    required: true,
    default: () => ({
      1: false,
      2: false,
      3: false,
    }),
  },
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(["content-saved", "content-published"]);

const featureStore = useFeatureStore();
const assetStore = useAssetStore();
const router = useRouter();

const SocialPlatform = {
  INSTAGRAM: 1,
  FACEBOOK: 2,
  TWITTERX: 3,
};

const keywords = ref([]);
const languageId = ref(assetStore.assetLanguage);
const selectedPlatforms = ref([]);
const generatedContent = ref(null);
const isGenerating = ref(false);
const isSaving = ref(false);
const isPublishing = ref(false);
const rowId = ref(null);
const isSaved = ref(false);

const canGenerate = computed(
  () =>
    keywords.value.length > 0 &&
    languageId.value &&
    selectedPlatforms.value.length > 0
);
const canSave = computed(() => generatedContent.value && !isSaved.value);
// const canPublish = computed(() => isSaved.value && selectedPlatforms.value.length > 0 && selectedPlatforms.value.every(platform => props.socialAuths[platform]))
const canPublish = computed(
  () => isSaved.value && selectedPlatforms.value.length > 0
);
const getPlatformKey = (value) => {
  return Object.keys(SocialPlatform).find(
    (key) => SocialPlatform[key] === value
  );
};

const getCurrentDate = () => {
  const userLocale = navigator.language || "en-US";
  return dayjs().locale(userLocale).format("LL");
};

const togglePlatform = (platform) => {
  const index = selectedPlatforms.value.indexOf(platform);
  if (index === -1) {
    selectedPlatforms.value.push(platform);
  } else {
    selectedPlatforms.value.splice(index, 1);
  }
};

const selectInitialPlatform = () => {
  selectedPlatforms.value = Object.keys(SocialPlatform)
    .filter((key) => !props.loading)
    .map((key) => SocialPlatform[key]);
};

const generateContent = async () => {
  isGenerating.value = true;
  try {
    const response = await axios.post(socialMedia.postGenerate, {
      assetId: assetStore.assetId,
      keywords: keywords.value.map((k) => k.name),
      languageId: languageId.value,
    });
    const content = response.data.data;
    generatedContent.value = {
      message: content.socialMediaContent,
      hashtags: JSON.parse(content.socialMediaHashtags),
      link: content.link,
    };
    isSaved.value = false;
  } catch (error) {
    responseHandlerService.handleError(
      error,
      t("socialMedia.components.createPost.errors.contentGeneration")
    );
    generatedContent.value = null;
  } finally {
    isGenerating.value = false;
    await featureStore.refreshFeatures();
  }
};

const savePost = async () => {
  isSaving.value = true;
  try {
    const response = await axios.post(socialMedia.savePost, {
      assetId: assetStore.assetId,
      keywords: keywords.value.map((k) => k.name),
      message: generatedContent.value.message,
      hashtags: generatedContent.value.hashtags,
      link: generatedContent.value.link,
    });
    rowId.value = response.data.data;
    isSaved.value = true;
    emit("content-saved");
  } catch (error) {
    responseHandlerService.handleError(
      error,
      t("socialMedia.components.createPost.errors.contentSaving")
    );
  } finally {
    isSaving.value = false;
  }
};

const publishPost = async () => {
  isPublishing.value = true;
  try {
    await axios.post(socialMedia.publishPost, {
      assetId: assetStore.assetId,
      platforms: selectedPlatforms.value,
      rowId: rowId.value,
    });
    await router.push({ query: { tab: "posts" } });
  } catch (error) {
    responseHandlerService.handleError(
      error,
      t("socialMedia.components.createPost.errors.contentPublishing")
    );
  } finally {
    isPublishing.value = false;
  }
};

const truncatedLink = computed(() => {
  if (!generatedContent.value?.link) return "";
  try {
    const url = new URL(generatedContent.value.link);
    // Sadece domain kısmını döndürüyor (örn: "example.com")
    return `${url.hostname}...`;
  } catch {
    return generatedContent.value.link;
  }
});

onMounted(() => {
  selectInitialPlatform();
});

watch(
  () => props.loading,
  (newLoading) => {
    if (!newLoading) {
      selectInitialPlatform();
    }
  }
);
</script>
